import React, {Fragment} from 'react';
import {Badge, Col, ToggleButton} from "react-bootstrap";
import Moment from "moment";
import {useSelector} from "react-redux";

function AnswerContent({answer, btnIndex}) {
    const authState = useSelector(state=>state.auth);
    //voters
    let voters = [];
    if (answer.voters && authState.user && answer.voters.length>0) {
        voters = answer.voters.map(voter=> {
            if (Number(voter.id)===Number(authState.user.id)) {

            } else {
                return (<Badge pill={true}
                               text="secondary"
                               key={"voters-"+ btnIndex + "-" + voter.id}
                               bg="dark"
                               data-bs-theme="dark">{voter.name}</Badge>
                );
            }
        });
    }
    if (answer.checked) { //own vote
        voters.push((<Badge pill={true}
                            text="light"
                            key={"voters-"+ btnIndex + "-" + authState.user.id}
                            bg="success"
                            data-bs-theme="dark">+1</Badge>
        ));
    } else {
        voters = voters.filter((voter) => voter);
        if (voters.length === 0) { //save the empty space
            voters = [<div key={"voters-" + btnIndex + "-" + 0}>&nbsp;</div>];
        }
    }

    let votesBadge = (
        <Badge pill={false}
               text="light"
               data-bs-theme="dark"
               key={"badge-" + btnIndex}
               id={"votes-badge-" + btnIndex}
               bg={Number(answer.votes)>0?"primary":"dark"}
               className="position-absolute top-50 translate-middle end-0"
        >{answer.votes}</Badge>
    );

    return (
        <Fragment>
            <Col xs={11} className="fs-6">
                <Col xs={12} className="fs-6 text-start">{answer.text}</Col>
                <Col xs={12} className="fs-6 text-start">{voters}</Col>
            </Col>
            <Col xs={1}>{votesBadge}</Col>
        </Fragment>
    );
}

export default AnswerContent;