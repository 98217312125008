import React, {useState } from 'react';
import { ToggleButton, ToggleButtonGroup, ListGroup} from 'react-bootstrap';
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import {useSelector, useDispatch} from "react-redux";
import {pollActions} from "../../store/pollSlice";
import AnswerContent from "./AnswerContent";

function PollAnswersToggle(props) {
    const [availableAnswers, setAvailableAnswers] = useState(props.answers);
    const [uiToggleSelection, setUiToggleSelection] = useState(props.uiToggleSelection);
    const authState = useSelector(state=>state.auth);
    const dispatch = useDispatch();

    const handleChange = (value, event) => {
        if (!props.isActive) {
            return;
        }
        const clickedId = event.target.id;
        let votesIncrement = 0;
        if (value.length > uiToggleSelection.length) { //increase
            votesIncrement = 1;
            if (availableAnswers[clickedId].checked===false) {
                availableAnswers[clickedId].checked = true;
            }
        }
        if (value.length < uiToggleSelection.length) { //decrease
            votesIncrement = -1;
            if (availableAnswers[clickedId].checked===true) {
                availableAnswers[clickedId].checked = false;
            }
        }
        availableAnswers[clickedId].votes = Number(availableAnswers[clickedId].votes) + votesIncrement;
        dispatch(pollActions.modifyVotesCount(votesIncrement));
        setAvailableAnswers(availableAnswers);
        setUiToggleSelection(value);
        let jsonObject = {
            id:props.pollId,
            votes:value
        }
        RestClient.PostRequest(AppUrl.VotePoll,
            JSON.stringify(jsonObject),
            {bearer:authState.token})
            .then((resData)=>{

            }).catch((resData)=>{

            });
    }

    return (
        <ToggleButtonGroup
           className="w-100"
           type="checkbox"
           size="lg"
           data-bs-theme="dark"
           value={uiToggleSelection}
           vertical={true}
           onChange={handleChange}>
            {availableAnswers.map((answer, btnIndex)=>(
                <ToggleButton
                    className="d-flex justify-content-between border-secondary-subtle"
                    key={"answer-" + btnIndex}
                    id={btnIndex}
                    checked={answer.checked}
                    variant="outline-secondary"
                    data-bs-theme="dark"
                    value={btnIndex}>
                    <AnswerContent
                    startDate={props.date}
                    answer={answer}
                    btnIndex={btnIndex}
                    />
                </ToggleButton>
                ))}
        </ToggleButtonGroup>
    );
}

export default PollAnswersToggle;