import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    //logbooks
    logbooks: [],
    logbooksRender: 0,
    selectedLogbookId: 0, //id
    logbookIsLoading: true,
    //items
    items: [],
    itemsRender:0,
    itemsAreLoading: true,
    //products
    products:[],
    //meetings
    meetings:[],
    //participants
    participants:[],
}

export const logbookSlice = createSlice({
    name:'logbook',
    initialState,
    reducers: {
        setLogbooks(state, action) {
            state.logbooks = action.payload;
        },
        setSelectedLogbookId(state, action) {
            state.selectedLogbookId = action.payload;
        },
/*
        setSelectedLogbook(state, action) {
            state.selectedLogbook = action.payload;
        },

 */
        //items
        setItems(state, action) {
            state.items = action.payload;
        },
        addItem(state, action) {
            state.items = [action.payload,...state.items]; //add as first element
            //state.items.push(action.payload);
        },
        deleteItem(state, action) {
            state.items = state.items.filter((item)=>item.id!==action.payload);
        },
        clearItems(state, action) {
            state.items = [];
        },
        //data
        setLogbookIsLoading(state, action) {
            state.logbookIsLoading = action.payload;
        },
        setItemsAreLoading(state, action) {
            state.itemsAreLoading = action.payload;
        },
        increaseLogbooksRender(state) {
            state.logbooksRender = state.logbooksRender + 1;
        },
        increaseItemsRender(state) {
            state.itemsRender = state.itemsRender + 1;
        },
        setProducts(state, action) {
            state.products = action.payload;
        },
        setMeetings(state, action) {
            state.meetings = action.payload;
        },
        setParticipants(state, action) {
            state.participants = action.payload;
        },
    },//end reducers
});

//reducer
export default logbookSlice.reducer;
//actions
export const logbookActions = logbookSlice.actions;
//selectors
export const getProducts = (state) => state.logbook.products;
export const getMeetings = (state) => state.logbook.meetings;
export const getParticipants = (state) => state.logbook.participants;
export const getItemsAreLoading = (state) => state.logbook.itemsAreLoading;
//selectors for logbooks
export const getLogbooks = (state) => state.logbook.logbooks;
export const getLogbookIsLoading = (state) => state.logbook.logbookIsLoading;
export const getSelectedLogbookId = (state) => state.logbook.selectedLogbookId;
export const getSelectedLogbook = (state) => state.logbook.logbooks.find(logbook => Number(logbook.id) === Number(state.logbook.selectedLogbookId));
//aux
export const getLogbooksRender = (state) => state.logbook.logbooksRender;
export const getItemsRender = (state) => state.logbook.itemsRender;

//selectors for items
export const getItems = (state) => state.logbook.items;
export const createEmptyItem = () => {
    return {
        id:0,
        name:"",
        quantity:1,
        price:"",
        productId:0,
        shared:'N',
        shares:[],
    }
};

/*
export const getOperatedItem = (state) => state.logbook.operatedItem;
export const getTotalQuantity = (state) => state.logbook.items.reduce((total, item)=>total + item.quantity, 0);
export const getTotalPrice = (state) => state.logbook.items.reduce((total, item)=>total + item.quantity * item.price, 0);
export const getTotalItemsWithoutPrice = (state) => state.logbook.items.reduce((total, item)=>{
    if (item.price<=0) {
        total = total + item.quantity;
    }
    return total;
}, 0);
*/

/*
export const getSummaryText=(state)=>{
    let totalQuantity = getTotalQuantity(state);
    let totalPrice = getTotalPrice(state);
    let itemsWithoutPrice = getTotalItemsWithoutPrice(state);
    let localText = "";
    if (totalQuantity && totalQuantity > 0) {
        if (totalPrice && totalPrice > 0) {
            localText = totalQuantity + " items with a total of " + totalPrice;
        } else {
            localText = totalQuantity + " items found";
        }
        if (itemsWithoutPrice && itemsWithoutPrice > 0) {
            localText = localText + " ( " + itemsWithoutPrice + " item without price )";
        }
    } else {
        localText = "Create a logbook by adding a new item";
    }
    return localText;
}
 */