import { createSlice } from "@reduxjs/toolkit";
import Moment from "moment";

const initialState = {
    signedIn: false,
    expires: '',
    user: null,
    token: '',
    isLoading:true,
    error:'',
}

export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers: {
        signIn(state, action) {
            if (action.payload && action.payload.user && action.payload.expires && action.payload.token) {
                state.signedIn = true;
                state.user = action.payload.user;
                state.expires = action.payload.expires;
                state.token = action.payload.token;
            }
        },
        signOut(state) {
            state.signedIn = false;
            state.user = null;
            state.token = '';
        },
        updateToken(state, action) {
            if (!action.payload) { //new data is invalid
                authSlice.caseReducers.verifyToken(state); //check current state
                return;
            }
            if (action.payload.valid===0) {
                state.expires = null;
            }
            if (Moment(action.payload.expires).isBefore(Moment())) { //time is expired
                authSlice.caseReducers.signOut(state);
            } else { //time is valid
                state.expires = action.payload.expires;
            }
            authSlice.caseReducers.verifyToken(state);
        },
        verifyToken(state) {
            if (!state.expires || state.expires===''
                || !state.token
                || Moment(state.expires).isBefore(Moment())) {
                authSlice.caseReducers.signOut(state);
            }
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
    },//end reducers
});

//reducer
export default authSlice.reducer;
//actions
export const authActions = authSlice.actions;
//selectors
export const getIsLoading = (state) => state.auth.isLoading;
export const getSignedIn = (state) => state.auth.signedIn;
export const getError = (state) => state.auth.error;