import React, {Component, Fragment} from 'react';
import {Spinner} from "react-bootstrap";

class UiSpinner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: props.message
        }
    }
    render() {
        return (
            <Fragment>
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                <div className="text-center">
                    {this.state.message}
                </div>
            </Fragment>
        );
    }
}

export default UiSpinner;