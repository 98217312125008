import React, {useState, useEffect, Fragment} from 'react';
import {Form, FloatingLabel} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {getLogbooks, getLogbooksRender, getSelectedLogbookId, logbookActions} from "../../store/logbookSlice";
import Moment from "moment";

function LogbookSelector() {
    const dispatch = useDispatch();
    const logbooks = useSelector(getLogbooks);
    const selectedLogbookId = useSelector(getSelectedLogbookId);
    const logbooksRender = useSelector(getLogbooksRender);

    let defaultValue = selectedLogbookId?selectedLogbookId:0; //default value
    const [value, setValue] = useState(defaultValue); //state for handles
    const [viewComponent, setViewComponent] = useState(<Fragment />);

    const handleLogbookChange=(e)=>{
        if (!e.target.value) {
            return;
        }
        dispatch(logbookActions.setSelectedLogbookId(e.target.value));
        dispatch(logbookActions.increaseItemsRender());
        setValue(e.target.value);
    }

    useEffect(() => { //component effect
        if (defaultValue!=value) {
            setValue(defaultValue);
        }
    },[defaultValue]);

    useEffect(() => { //items effect
        setViewComponent(renderViewComponent());
    },[]);

    useEffect(() => { //effect
        setViewComponent(renderViewComponent());
    },[logbooksRender, logbooks, value]);

    const renderViewComponent=()=>{
        return (
            <FloatingLabel
                controlId="selectLogbook"
                label="Select logbook"
                data-bs-theme="dark"
            >
                <Form.Select
                    data-bs-theme="dark"
                    id="selectLogbook"
                    onChange={handleLogbookChange}
                    value={value}
                >
                    <option key="0" value={0}>New logbook</option>
                    {logbooks.map((resData) => (
                        <option key={resData.id} value={resData.id}>{resData.name} - {
                            Moment(resData.date).format('DD.MM.YYYY')}</option>
                    ))}
                </Form.Select>
            </FloatingLabel>
        );
    }

    return (
        <Fragment>{viewComponent}</Fragment>
    );
}

export default LogbookSelector;