import React, {Fragment} from 'react';
import {Badge, Table} from "react-bootstrap";
import {Cart2, CashCoin, Trophy, PersonCheck, CurrencyDollar, Person} from "react-bootstrap-icons";
import UiTableHeader from "../UI/UiTableHeader";

function CheckIndividual({meeting}) {
    let counter = 0;
    let totalItems = 0;
    let totalQuantity = 0;
    let totalPrice = 0;
    let countInvalidPriceItems = 0;
    let countSharedItems = 0;

    //count and sum
    meeting.logbooks.map(logbook=>{
        totalItems += logbook.total_items;
        totalQuantity += logbook.total_quantity;
        countInvalidPriceItems += logbook.total_items_invalid_price;
        totalPrice += logbook.total_price;
    });

    //define table rows
    const tableRows = meeting.logbooks.map(logbook=>{
        counter = counter + 1;
        let invalidClassName = "";
        if (logbook.total_items_invalid_price>0 || logbook.total_price<=0) {
            invalidClassName = "border border-danger";
        }

        return (<tr key={"th-"+logbook.id}>
                <td className={invalidClassName}>{logbook.user_name}</td>
                <td className={invalidClassName} >{logbook.total_items}</td>
                {countInvalidPriceItems>0 && <td className={invalidClassName}>{logbook.total_items_invalid_price}</td>}
                <td className={invalidClassName} >{logbook.total_quantity}</td>
                <td className={invalidClassName}>{logbook.total_price}</td>
            </tr>
        )
    });
    return (
        <Table striped
               bordered
               hover
               data-bs-theme="dark"
               variant="dark"
               className="table-sm"
        >
            <thead>
            <tr key={"th-"+meeting.id}>
                <UiTableHeader
                    text={<Fragment>Nick</Fragment>}
                    icon={<Person className="fs-3" />}
                />
                <UiTableHeader
                    text={<Fragment>Items</Fragment>}
                    icon={<Cart2 className="fs-3" />}
                />
                {countInvalidPriceItems>0 &&
                <UiTableHeader
                    text={<Fragment>Invalid</Fragment>}
                    icon={<CashCoin className="fs-3" />}
                />}
                <UiTableHeader
                    text={<Fragment>Qty</Fragment>}
                    icon={<Cart2 className="fs-3" />}
                />
                <UiTableHeader
                    text={<Fragment>Total</Fragment>}
                    icon={<CurrencyDollar className="fs-3" />}
                />
            </tr>
            </thead>
            <tbody>
            {tableRows}
            <tr>
                <td>Total</td>
                <td>{totalItems}</td>
                {countInvalidPriceItems>0 && <td>{countInvalidPriceItems}</td>}
                <td>{totalQuantity}</td>
                <td>{totalPrice}</td>
            </tr>
            </tbody>
        </Table>
    );
}

export default CheckIndividual;