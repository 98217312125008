import React from 'react';
import {Accordion, Button, Col, Row} from "react-bootstrap";
import ItemForm from "./ItemForm";
import {PlusCircle} from "react-bootstrap-icons";

function AddItemAccordion({addedItem, itemsRender, handleAddItemSubmit, setAddedItem, isAddingItem,
                              products, participants}) {
    return (
        <Accordion.Item key={"ia-0-"+itemsRender} eventKey={0}>
            <Accordion.Header>Add new item</Accordion.Header>
            <Accordion.Body>
                <ItemForm item={addedItem}
                          handleSubmit={handleAddItemSubmit}
                          handleInput={setAddedItem}
                          products={products}
                          participants={participants}
                />
                <Row className="gy-2 py-2 px-1 justify-content-between">
                    <Button as={Col}
                            xs={12}
                            disabled={isAddingItem}
                            className="p-2"
                            variant={isAddingItem?"outline-secondary":"outline-success"}
                            onClick={handleAddItemSubmit}
                    >
                        <PlusCircle /> Add
                    </Button>
                </Row>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default AddItemAccordion;