import React, {Fragment} from 'react';
import {Table} from "react-bootstrap";
import UiTableHeader from "../UI/UiTableHeader";
import {CurrencyDollar, Person, Trophy} from "react-bootstrap-icons";

function CheckTop({meeting}) {
    let counter = 0;
    let totalPrice = 0;
    if (!meeting || !meeting.totals) {
        return (<Fragment></Fragment>);
    }
    const tableRows = meeting.totals.map(res=>{
        counter = counter + 1;
        totalPrice += res.total;

        return (<tr key={"th-"+res.wid+"-"+counter}>
                <td>{counter}</td>
                <td>{res.name}</td>
                <td>{res.total}</td>
            </tr>
        )
    });
    return (
        <Table striped
               hover
               bordered
               data-bs-theme="dark"
               variant="dark"
               className="table-sm"
        >
            <thead>
            <tr key={"tht-"+meeting.id}>
                <UiTableHeader
                    text={<Fragment>#</Fragment>}
                    icon={<Trophy className="fs-3" />}
                />
                <UiTableHeader
                    text={<Fragment>Nick</Fragment>}
                    icon={<Person className="fs-3" />}
                />
                <UiTableHeader
                    text={<Fragment>Total</Fragment>}
                    icon={<CurrencyDollar className="fs-3" />}
                />
            </tr>
            </thead>
            <tbody>
            {tableRows}
            <tr>
                <td colSpan={2}>Total</td>
                <td>{totalPrice}</td>
            </tr>
            </tbody>
        </Table>
    );
}

export default CheckTop;