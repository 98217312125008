import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import SignIn from "../components/SignIn/SignIn";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Footer from "../components/Footer/Footer";

function SignInPage() {
    const isSignedIn = useSelector(state=>state.auth.signedIn);
    return (
        <Fragment>
            <div className="backgroundSettings">
                <TopNavigation />
                <div className="servicesOverlay">
                    {!isSignedIn && <SignIn />}
                </div>
                <Footer />
            </div>
        </Fragment>
    );
}

export default SignInPage;