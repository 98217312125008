import React, { useEffect} from 'react';
import AppRouter from "./router/AppRouter";
import { BrowserRouter } from "react-router-dom";
import RestClient from "./restapi/RestClient";
import AppUrl from "./restapi/AppUrl";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "./store/authSlice";

function App() {
    const authState = useSelector(state=>state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (authState.signedIn) {
            RestClient.PostRequest(AppUrl.VerifyToken, {}, {bearer: authState.token})
                .then((resData) => {
                    if (resData!==undefined && resData.expires!==undefined) {
                        dispatch(authActions.updateToken(resData));
                    }
                })
                .catch((resData) => {
                    //dispatch(authActions.signOut());
                })
                .finally(()=>{
                    dispatch(authActions.verifyToken());
                });
        }
    },[authState.token]);
    return (
      <BrowserRouter basename="/">

        <AppRouter />
      </BrowserRouter>
    );
}

export default App;
