import React, {Fragment, useState, useEffect} from 'react';
import { ToggleButton, Alert, Row, Col, Form, FloatingLabel} from "react-bootstrap";
import {CheckCircle, PlusCircle} from "react-bootstrap-icons";

function ItemSharing({item, setSharedMode, setShares, participants}) {

    const sharingModes = [
        {"mode":"N","name":"Not shared"},
        {"mode":"E","name":"Split equally"},
        //{"mode":"P","name":"By percentage"}
    ];

    const [localShares, setLocalShares] = useState(item.shares);
    const [localMode, setLocalMode] = useState(item.shared);
    const [viewPartList, setViewPartList] = useState(<Fragment />);

    const consoleLogIS=(logText)=> {
        logText += " : id="+item.id+" : "+item.name+" : ";
        if (localShares) {
            logText += "  localShares.length=" + localShares.length;
        }
        if (item.shares) {
            logText += "  item.shares=" + item.shares.length;
        }
        if (participants) {
            logText += "  participants=" + participants.length;
        }
        if (localMode) {
            logText += "  localMode=" + localMode;
        }
        if (item.shared) {
            logText += "  item.sharedMode=" + item.shared;
        }

        /*
        if (activeKey) {
            logText += "  activeKey=" + activeKey;
        }
        */
        if (localShares) {
            logText += "  localShares=";
        }
        //console.log(logText, localShares);
    }

    //consoleLogIS("ItemSharing render");

    const canRender=()=>{
        if (item && participants && participants.length>0) {
            if (item.shared) {
                return true;
            } else {
                if (localMode) {
                    return true;
                }
            }
        }
        return false;
    }

    useEffect(() => { //update from parent to local
        if (canRender()) {
            setLocalShares(item.shares);
            setLocalMode(item.shared);
            setViewPartList(renderViewParticipants());
        }
        //consoleLogIS("ItemSharing effect 3H: ");
    //},[ itemsRender, participants ]);
    },[  participants ]);

    useEffect(() => { //update from parent to local
        if (canRender()) {
            setLocalShares(item.shares);
            setLocalMode(item.shared);
            //setViewPartList(renderViewParticipants());
        }
        //consoleLogIS("ItemSharing effect first");
    },[]);

    useEffect(() => { //update from local to parent
        if (canRender()) {
            setViewPartList(renderViewParticipants());
        } else {

        }
        //consoleLogIS("ItemSharing effect localShares");
    },[ localShares ]);

    useEffect(() => {
        if (canRender()) {
            setViewPartList(renderViewParticipants());
        }
        //console.log("effect localMode: ",localShares);
    },[ localMode ]);

    const handleModeChange=(e)=> {
        //console.log("handleModeChange ",e.target.value);
        if (!e.target.value || e.target.value==="N") {
            setSharedMode('N'); //update from child to parent
            setLocalMode('N');
        } else {
            setSharedMode(e.target.value); //update from child to parent
            setLocalMode(e.target.value);
        }
        if (e.target.value==="E") {
            //setColContainerSize(2);
        }
        if (e.target.value==="P") {
            //setColContainerSize(4);
        }
    }//end handleModeChange

    const participantIsSelected=(participant)=> {
        return localShares.filter((share) => Number(share.wid) === Number(participant.id)).length>0;
    }

    const toggleSharedWith=(participant)=> {
        let operatedShares = [];
        if (participantIsSelected(participant)) {
            operatedShares = localShares.filter((share)=>Number(share.wid) !== Number(participant.id));
            //console.log("ItemSharing: toggleSharedWith: remove participant ");
        } else {
            //console.log("ItemSharing: toggleSharedWith: add participant ");
            operatedShares = [...localShares, {wid:participant.id, percent:null}];
        }
        //console.log("ItemSharing: toggleSharedWith: operatedShares ",operatedShares.length);
        setShares(operatedShares); //update from child to parent
        setLocalShares(operatedShares);
        setLocalMode('E');
        setSharedMode('E');
    }

    if (!canRender()) {
        //console.log("item render mode invalid", localMode);
        return (<Fragment></Fragment>);
    }

    const renderViewParticipants=()=>{
        //if (!localMode || localMode==='N' || itemsAreLoading) {
        if (!localMode || localMode==='N') {
            //console.log("renderViewParticipants mode invalid" , localMode);
            return (<Fragment></Fragment>);
        }
        let countPart = participants.length;
        //console.log("renderViewParticipants: localShares=",localShares);
        let participantsView = participants.map((puser, idx) => {
            let isChecked = participantIsSelected(puser);

            let textPosition = "center";
            let midBtnClass = "border-light-subtle ";
            if (isChecked) {
                midBtnClass = "border-success-subtle ";
                //console.log("is selected: ",puser.name);
            } else {
                //console.log("not selected: ",puser.name);
            }
            if (idx%3==0) {
                //textPosition = "start"
            }
            if (idx%3==1) { //middle
                if (countPart-idx>=2) {
                    midBtnClass = midBtnClass + "mx-2";
                }
            }
            if (idx%3==2) {
                //textPosition = "end"
            }
            return (
                <Col xs={4}
                     key={`col-pv-${item.id}-${idx}`}
                     className={"p-0 px-0 d-grid text-"+textPosition}>
                    <ToggleButton
                        className={"border "+midBtnClass}
                        key={`tb-${item.id}-${idx}`}
                        id={`tb-share-${item.id}-${idx}`}
                        type="checkbox"
                        variant="outline-secondary"
                        name={`tb-share-${item.id}-${idx}`}
                        value={puser.id}
                        checked={isChecked}
                        onChange={()=>toggleSharedWith(puser)}
                    >
                        {isChecked && <CheckCircle key={`cbtn-${item.id}-${idx}`} className="text-success-emphasis" />}
                        {!isChecked && <PlusCircle key={`pbtn-${item.id}-${idx}`} />}
                        <Fragment> {puser.name}</Fragment>
                    </ToggleButton>
                </Col>
                );
        });
        return participantsView;
    }//end


    return (
        <Fragment>
        <Row className="gy-2 py-2 px-1 justify-content-between">
            <Col xs={12} key={"col-share-"+item.id} className="p-0 px-0" >
                <FloatingLabel
                    controlId="selectLogbook"
                    label="Share this item"
                    data-bs-theme="dark"
                >
                    <Form.Select
                        data-bs-theme="dark"
                        id="sharingMode"
                        onChange={handleModeChange}
                        value={localMode}
                    >
                        {sharingModes.map((opData,idx) => (
                            <option key={`opt-${item.id}-{opData.mode}-${idx}`}
                                    value={opData.mode}>{opData.name}</option>
                        ))}
                    </Form.Select>
                </FloatingLabel>
            </Col>
        </Row>
        <Row className="gy-2 py-0 pb-1 px-1 justify-content-between">
            {viewPartList}
        </Row>
        </Fragment>
    );
}

export default ItemSharing;