import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Footer from "../components/Footer/Footer";
import Logbook from "../components/Logbook/Logbook";

class LogbookPage extends Component {
    render() {
        return (
            <Fragment>
                <div className="backgroundSettings">
                    <TopNavigation />
                    <div className="servicesOverlay">
                        <Logbook />
                    </div>
                    <Footer />
                </div>
            </Fragment>
        );
    }
}

export default LogbookPage;