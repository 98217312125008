import React from 'react';
import {Badge} from "react-bootstrap";

function UiBadgeIcon({icon, text, className, badgeClassName}) {
    if (!className) {
        className = "m-1 p-1";
        if (text && text.toString().length>1) {
            className = className + " me-" + text.toString().length;
        }
    }
    if (!badgeClassName) {
        badgeClassName = "bg-secondary ";
    }

    return (
        <span className={`text-primary-emphasis d-inline ${className}`}>
            {icon}
            <Badge pill={false}
                   text="light"
                   data-bs-theme="dark"
                   bg="primary"
                   className={`position-absolute top-30 translate-middle ${badgeClassName}`}
            >{text}</Badge>
        </span>
    );
}

export default UiBadgeIcon;