import axios from 'axios';

class RestClient  {
    static GetRequest = async (getUrl, configData= {}) => {
        let config = RestClient.mapConfigData(configData);
        return await axios.get(getUrl,config)
            .then(response=>{
                return response.data;
            })
            .catch(error=>{
                return error.data;
        });
    }

    static PostRequest = async (postUrl, dataJson, configData= {}) => {
        let config = RestClient.mapConfigData(configData);
        return await axios.post(postUrl, dataJson, config)
            .then(response=>{
                //console.log("PostRequest: then:",response.data);
                return response.data;
            })
            .catch(error=>{
                //console.log("PostRequest: catch:",error.response);
                if (error.response && error.response.data && error.response.data.message) {
                    throw new Error(error.response.data.message);
                } else {
                    throw new Error("Invalid data");
                }
        });
    }

    static PostRequestAsync = async (postUrl, dataJson, configData= {}) => {
        let config = RestClient.mapConfigData(configData);
        const response = await axios.post(postUrl, dataJson, config);
        console.log("PostRequestAsync",response);
        console.log("data send");
        //return await response.json();
        return response;
/*
            .then(response=>{
                return response.data;
            })
            .catch(error=>{
                console.log(error.message);
            });
 */
    }

    static mapConfigData(configData) {
        let config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': ''
            }
        }
        if (configData && configData.bearer!==undefined ) {
            config.headers.Authorization = 'Bearer ' + configData.bearer;
        }
        if (configData && configData.signal!==undefined ) {
            config.signal = configData.signal;
        }
        if (configData && configData.timeout!==undefined ) {
            config.signal =  AbortSignal.timeout(configData.timeout);
        }
        return config;
    }
}

export default RestClient;