import React, {useState, useEffect, useRef, Fragment} from 'react';
import {Button, Col, Container, Form, Row, FloatingLabel} from "react-bootstrap";
import LogbookSelector from "./LogbookSelector";
import LogbookMenu from "./LogbookMenu";
import ItemsSummary from "../LogbookItems/ItemsSummary";
import {
    getItems, getItemsRender, getLogbooks,
    getLogbooksRender, getSelectedLogbookId,
    logbookActions
} from "../../store/logbookSlice";
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import {useDispatch, useSelector} from "react-redux";
import ItemsList from "../LogbookItems/ItemsList";
import {Trash3, X, CheckLg, Save} from "react-bootstrap-icons";
import UiSpinner from "../UI/UiSpinner";
import UiModal from "../UI/UiModal";
import LogbookEditModal from "./LogbookEditModal";
import LogbookDeleteModal from "./LogbookDeleteModal";
import ItemsOperator from "../LogbookItems/ItemsOperator";

function Logbook() {
    const dispatch = useDispatch();
    const authState = useSelector(state=>state.auth);
    //const logbookState = useSelector(state=>state.logbook);
    const selectedLogbookId = useSelector(getSelectedLogbookId);
    const logbooksRender = useSelector(getLogbooksRender);
    const logbookItems = useSelector(getItems);
    //const logbooks = useSelector(getLogbooks);

    const [showEdit, setShowEdit] = useState(false); //show bool for modal
    const [showDelete, setShowDelete] = useState(false); //show bool for modal

    const fetchLogbooks=()=>{
        dispatch(logbookActions.setLogbookIsLoading(true));
        RestClient.PostRequest(AppUrl.GetLogbooks,{},{bearer:authState.token})
            .then(resData=>{
                if (resData) {
                    dispatch(logbookActions.setLogbooks(resData.logbooks));
                }
                //console.log("fetchLogbooks ", selectedLogbookId,logbooksRender,resData.logbooks);
            })
            .catch(()=>{})
            .finally(()=>{
                dispatch(logbookActions.setLogbookIsLoading(false));
            });
    }

    const fetchProducts=()=>{
        RestClient.PostRequest(AppUrl.GetProducts,{},{bearer:authState.token})
            .then(resData=>{
                if (resData) {
                    dispatch(logbookActions.setProducts(resData.products));
                }
            })
            .catch(()=>{});
    }

    const fetchMeetings=()=>{
        RestClient.PostRequest(AppUrl.GetMeetings,{},{bearer:authState.token})
            .then(resData=>{
                if (resData) {
                    dispatch(logbookActions.setMeetings(resData.meetings));
                }
            })
            .catch(()=>{});
    }

    useEffect(() => { //component effect
        fetchLogbooks();
        fetchProducts();
        fetchMeetings();
    },[]);

    useEffect(() => { //data is dirty effect
        fetchLogbooks();
    },[logbooksRender]);

    const handleToggleEditLogbookModal=(e)=> {
        e.preventDefault();
        setShowEdit(!showEdit);
    }

    const handleToggleDeleteLogbookModal=(e)=> {
        e.preventDefault();
        setShowDelete(!showDelete);
    }

    const handleCopyLogbook=(e)=> {
        e.preventDefault();
        if (selectedLogbookId) {
            RestClient.PostRequest(AppUrl.CopyLogbook, {id: selectedLogbookId}, {bearer: authState.token})
                .then(resData => {
                    dispatch(logbookActions.increaseLogbooksRender());
                    dispatch(logbookActions.setSelectedLogbookId(resData.logbook_id));
                });
        }
    }

    return (
        <Fragment>
        <Container className="container overflow-hidden">
            <Row className="">
                <Col className="px-2" xs={12} sm={12} md={5} lg={5} xl={4} xxl={3}>
                    <Row className="m-1 justify-content-between">
                        <LogbookMenu
                            selectedLogbookId={selectedLogbookId}
                            hasItems={logbookItems.length}
                            handleEdit={handleToggleEditLogbookModal}
                            handleRemove={handleToggleDeleteLogbookModal}
                            handleCopy={handleCopyLogbook}
                        />
                    </Row>
                    <Row className="m-1 my-0 mt-2">
                        <Col xs={12} className="p-0">
                            <LogbookSelector />
                        </Col>
                        <Col xs={12} className="d-md-none">
                            <hr />
                        </Col>
                    </Row>
                </Col>
                <Col className="px-2 my-0 my-md-1" xs={12} sm={12} md={7} lg={7} xl={6}>
                    <ItemsOperator selectedLogbookId={selectedLogbookId} />
                </Col>
            </Row>
        </Container>

        <LogbookEditModal
            selectedLogbookId={selectedLogbookId}
            showEdit={showEdit}
            setShowEdit={setShowEdit} />

        <LogbookDeleteModal
            selectedLogbookId={selectedLogbookId}
            showDelete={showDelete}
            setShowDelete={setShowDelete} />
        </Fragment>
    );
}

export default Logbook;