import React, {Fragment, useState}  from 'react';
import UiModal from "../UI/UiModal";
import {Button, FloatingLabel, Form, Row, Col} from "react-bootstrap";
import {Save, X} from "react-bootstrap-icons";
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import {logbookActions} from "../../store/logbookSlice";
import {useDispatch, useSelector} from "react-redux";
import {pollActions} from "../../store/pollSlice";
import {getSignedIn} from "../../store/authSlice";
import Moment from "moment";
//import DateTimePicker from 'react-datetime-picker';
import Datetime from 'react-datetime';

function MeetingEditModal({poll, answers, showEdit, setShowEdit}) {
    const authState = useSelector(state=>state.auth);
    const dispatch = useDispatch();

    let availableAnswers = [...answers];
    availableAnswers.filter(answer=>answer.date);
    availableAnswers.sort((a, b) => {
        if (a.votes === b.votes) {
            return (a.voters < b.voters ? -1 : 1);
        }
        return (a.votes > b.votes ? -1 : 1);
    });

    const findDateInAnswers=(dateSearched)=>{
        let foundCounter = -1;
        dateSearched = Moment(dateSearched).format('YYYY-MM-DD')
        availableAnswers.map((answer, index)=> {
            if (answer.date) {
                if (Moment(answer.date).format('YYYY-MM-DD') === dateSearched) {
                    foundCounter = answer.counter;
                }
            }
        });
        return foundCounter;
    }

    //props
    let pLocation = 'Beerzone';
    let pDescription = '';
    let pTotal = 0;
    let pStatus = poll.status;
    let pDate = 0;
    let pHours = 19;
    let pMinutes = 0;
    let pDateTime = 0;
    let pParticipants = 0;
    if (poll.meeting) {
        pLocation = poll.meeting.location;
        pDescription = poll.meeting.description;
        pDate = findDateInAnswers(poll.meeting.result_dt);
        pHours = Moment(poll.meeting.result_dt).format('HH');
        pMinutes = Moment(poll.meeting.result_dt).format('mm');
        pDateTime = poll.meeting.result_dt;
        if (poll.meeting.total) {
            pTotal = poll.meeting.total;
        }
        if (poll.meeting.participants) {
            pParticipants = poll.meeting.participants;
        }
    }//

    const [status, setStatus] = useState(pStatus);
    const [dateOption, setDateOption] = useState(pDate);
    const [hours, setHours] = useState(pHours);
    const [minutes, setMinutes] = useState(pMinutes);
    const [dateTime, setDateTime] = useState(pDateTime);
    const [description,setDescription] = useState(pDescription);
    const [location,setLocation] = useState(pLocation);
    const [total,setTotal] = useState(pTotal);
    const [participants,setParticipants] = useState(pParticipants);

    const handleEditSubmit = (e) => {
        e.preventDefault();
        let foundDateAnswer = availableAnswers.find(answer=>Number(answer.counter)===Number(dateOption));
        if (status && foundDateAnswer) {
            //console.log(foundDateAnswer,foundDateAnswer.counter,Moment(foundDateAnswer.date).format('YYYY-MM-DD'));
            dispatch(pollActions.setIsLoading(true));
            RestClient.PostRequest(AppUrl.UpdatePollMeeting,
                {
                    id: poll.id,
                    status: status,
                    date: Moment(foundDateAnswer.date).format('YYYY-MM-DD'),
                    hours: hours,
                    minutes: minutes,
                    location: location,
                    total: total,
                    participants: participants,
                    description: description,
                },
                {bearer: authState.token})
                .then(resData => {
                    dispatch(pollActions.setPoll(resData.poll));
                })
                .catch(()=>{

                })
                .finally(()=>{
                    dispatch(pollActions.setIsLoading(false));
                    setShowEdit(false);
                });
            setShowEdit(false);
        }
    }

    const handeDateOptionChange=(e)=> {
        setDateOption(e.target.value);
        let foundDateAnswer = availableAnswers.find(answer=>Number(answer.counter)===Number(e.target.value));
        if (foundDateAnswer) {
            //console.log(foundDateAnswer);
            if (foundDateAnswer.votes!==null) {
                setParticipants(foundDateAnswer.votes);
            }
        }
    }

    const contentFormView = (
        <Form>
            <Row className="mt-0 mb-2">
            <Form.Group as={Col} className="m0-1 py-0" controlId="selectPollStatus">
                <FloatingLabel
                    controlId="selectPollStatus"
                    label="Select poll status"
                    data-bs-theme="dark"
                >
                    <Form.Select
                        data-bs-theme="dark"
                        onChange={(e)=>setStatus(e.target.value)}
                        value={status}
                    >
                        <option key="spr-v" value="vote">Poll is enabled, wait for votes</option>
                        <option key="spr-f" value="fail">Quorum is not met, disable poll</option>
                        <option key="spr-m" value="meet">Quorum is met, disable poll and define meeting</option>
                        <option key="spr-e" value="end">Meeting took place, end poll and save meeting</option>
                      </Form.Select>
                </FloatingLabel>
            </Form.Group>
            </Row>
            {(status==='meet' || status==='end') &&
            <Fragment>
                <Row className="mt-0 mb-2">
                    <Form.Group as={Col} xs={6} className="m-0 pe-1" controlId="selectDate">
                        <FloatingLabel
                            controlId="selectDate"
                            label="Select date *"
                            data-bs-theme="dark"
                        >
                            <Form.Select
                                data-bs-theme="dark"
                                value={dateOption}
                                onChange={handeDateOptionChange}
                            >
                                {availableAnswers.map((answer, index) => {
                                    if (answer.date) {
                                        return (
                                            <option key={"opt-" + poll.id + "-" + answer.counter} value={answer.counter}>
                                                {Moment(answer.date).format('DD.MM.YYYY')} - {answer.votes} votes
                                            </option>);
                                    }
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col} xs={3} className="p-0 m-0" controlId="selectHours">
                        <FloatingLabel
                            controlId="selectHours"
                            label="H"
                            data-bs-theme="dark"
                        >
                            <Form.Select
                                data-bs-theme="dark"
                                className="d-inline"
                                value={hours}
                                onChange={(e)=>setHours(e.target.value)}
                            >
                                {Array.from({length:24},(_,i)=>i+1).map((num)=> {
                                    num = num - 1;
                                    let vNumber = num;
                                    if (vNumber<10) {
                                        vNumber = "0" + (num);
                                    }
                                    return (<option value={num} key={"h" + num}>{vNumber}</option>);
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col} xs={3} className="m-0 ps-1" controlId="selectMinutes">
                        <FloatingLabel
                            controlId="selectMinutes"
                            label="M"
                            data-bs-theme="dark"
                        >
                            <Form.Select
                                data-bs-theme="dark"
                                value={minutes}
                                onChange={(e)=>setMinutes(e.target.value)}
                            >
                                {Array.from({length:60},(_,i)=>i+1).map((num)=> {
                                    num = num - 1;
                                    let vNumber = num;
                                    if (vNumber<10) {
                                        vNumber = "0" + (num);
                                    }
                                    return (<option value={num} key={"h" + num}>{vNumber}</option>);
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mt-0 mb-2">
                    <Form.Group as={Col} xs={8} className="m-0 pe-1"  controlId="inputLocation">
                        <FloatingLabel
                            controlId="inputLocation"
                            label="Meeting location *"
                            data-bs-theme="dark"
                        >
                            <Form.Control
                                placeholder="Meeting Location"
                                value={location}
                                required={true}
                                onChange={(e)=>setLocation(e.target.value)}
                            />
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group as={Col} xs={4} className="m-0 ps-0"  controlId="inputParticipants">
                        <FloatingLabel
                            controlId="inputParticipants"
                            label="Participants *"
                            data-bs-theme="dark"
                        >
                            <Form.Control
                                placeholder="Participants"
                                value={participants}
                                required={true}
                                onChange={(e)=>setParticipants(e.target.value)}
                            />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row className="mt-0 mb-2">
                    <Form.Group as={Col} xs={12} className="m0-1 py-0" controlId="inputDescription">
                        <FloatingLabel
                            controlId="inputDescription"
                            label="Meeting description "
                            data-bs-theme="dark"
                        >
                            <Form.Control
                                as="textarea"
                                rows={2}
                                placeholder="Meeting description"
                                value={description}
                                required={true}
                                onChange={(e)=>setDescription(e.target.value)}
                            />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
            </Fragment>
            }
            {status==='end' &&
                <Row className="mt-0 mb-2">
                    <Form.Group as={Col} xs={12} className="m0-1 py-0"  controlId="inputTotal">
                        <FloatingLabel
                            controlId="inputTotal"
                            label="Total spent at meeeting"
                            data-bs-theme="dark"
                        >
                            <Form.Control
                                placeholder="Logbook Location"
                                value={total}
                                onChange={(e)=>setTotal(e.target.value)}
                            />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
            }
        </Form>
    );

    return (
        <UiModal
            show={showEdit}
            setShow={setShowEdit}
            title="Edit"
            content={contentFormView}
        >
            <Button
                variant="outline-warning"
                onClick={handleEditSubmit}
            >
                <Save /> Save
            </Button>
            <Button
                variant="outline-secondary"
                onClick={(e)=>setShowEdit(false)}
            >
                <X /> Cancel
            </Button>
        </UiModal>
    );
}

export default MeetingEditModal;