import React, {Fragment, useEffect, useState} from 'react';
import {Alert, Col, Row, Toast} from "react-bootstrap";
import UiBadgeIcon from "../UI/UiBadgeIcon";
import {Cart2, CashCoin, PersonCheck, CurrencyDollar, PieChart} from "react-bootstrap-icons";

function ItemsSummary({selectedLogbookId, logbookItems}) {
    const [summaryView, setSummaryView] = useState("");

    const composeSummaryView=()=>{
        let totalQuantity = 0;
        let totalPrice = 0;
        let countInvalidPriceItems = 0;
        let countSharedItems = 0;
        logbookItems.map(item=>{
            let itemPrice = Number(item.quantity) * Number(item.price);
            if (item.shares && item.shares.length>0) {
                let parts = item.shares.length + 1;
                itemPrice = Math.round(itemPrice / parts);
                countSharedItems = countSharedItems + + Number(item.quantity);
            }
            totalQuantity = totalQuantity + Number(item.quantity);
            totalPrice = totalPrice + itemPrice;
            if (!item.price || item.price===0) {
                countInvalidPriceItems = countInvalidPriceItems + 1;
            }
        });
        let localView = null;
        let localComps = [];
        if (totalQuantity && totalQuantity > 0) {
            /*
            localComps.push(<Fragment>Contains {totalQuantity} </Fragment>);
            if (totalQuantity>1) {
                localComps.push(<Fragment>items </Fragment>);
            } else {
                localComps.push(<Fragment>item </Fragment>);
            }
            if (totalPrice && totalPrice > 0) {
                localComps.push(<Fragment>with a total of {totalPrice}</Fragment>);
            }
            if (countInvalidPriceItems && countInvalidPriceItems > 0) {
                localComps.push(<Fragment> ( {countInvalidPriceItems} with invalid price )</Fragment>);
            }
            localView = (<Alert variant="primary" className="border border-light-subtle" data-bs-theme="dark">
                <small>{localComps.map((lc)=>lc)}</small></Alert>);
            */
            localView = (
                <Alert variant="primary" className="border border-light-subtle" data-bs-theme="dark">
                    {countSharedItems > 0 &&
                        <UiBadgeIcon
                            icon={<PieChart className="fs-3"/>}
                            text={countSharedItems}
                        />
                    }
                    <UiBadgeIcon
                        icon={<Cart2 className="fs-3" />}
                        text={totalQuantity}
                        className="p-3 ps-4"
                    />
                    <UiBadgeIcon
                        icon={<CurrencyDollar className="fs-3" />}
                        text={totalPrice}
                    />
                    {countInvalidPriceItems>0 &&
                        <UiBadgeIcon
                            icon={<CashCoin className="fs-3" />}
                            text={countInvalidPriceItems}
                            badgeClassName="bg-danger"
                        />
                    }
                </Alert>
            );

        } else {
            if (selectedLogbookId>0) {
                localView =  (<Alert variant="primary" data-bs-theme="dark">
                <small>Selected logbook has no records, start by adding the first item</small></Alert>);
            } else {
                localView = (<Alert variant="primary" data-bs-theme="dark">
                    <small>Adding a new item will create automatically a new logbook</small></Alert>);
            }
        }
        return localView;
    }

    useEffect(() => { //data is dirty effect
        let sView = composeSummaryView();
        setSummaryView(sView);
    },[logbookItems]);

    return (
        <Row className="">
            <Col xs={12} className="m-0 p-0 fw-lighter">
                {summaryView}
            </Col>
        </Row>
    );
}

export default ItemsSummary;