import { configureStore } from '@reduxjs/toolkit';
import authSlice from "./authSlice";
import pollSlice from "./pollSlice";
import profileSlice from "./profileSlice";
import logbookSlice from "./logbookSlice";
import logger from 'redux-logger';
import { persistStore, persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
    blacklist:['auth']
};

const persistedAuthReducer = persistReducer(persistConfig, authSlice)

const middlewares = [process.env.NODE_ENV !== 'production' && logger].filter(Boolean);

export const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        poll: pollSlice,
        profile: profileSlice,
        logbook: logbookSlice,
    },
    //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
});

export const persistor = persistStore(store);

