import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import {authActions} from "../../store/authSlice";

function Profile(props) {
    const isSignedIn = useSelector(state=>state.auth.signedIn);
    const authState = useSelector(state=>state.auth);

    return (
        <Fragment>
            {isSignedIn && <span>You are logged in as {authState.user.name}</span>}
            <br />
            <span>Profile page is under construction</span>
        </Fragment>
    );
}

export default Profile;
