import React, {Fragment, useState, useEffect, useRef} from 'react';
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import PollAnswersToggle from "../PollAnswersToggle/PollAnswersToggle";
import MeetingEditModal from "../Meeting/MeetingEditModal";
import {useSelector, useDispatch} from "react-redux";
import {pollActions} from "../../store/pollSlice";
import UiSpinner from "../UI/UiSpinner";
import {Row, Col, Button, Form, ToggleButton, Alert, Badge} from 'react-bootstrap';
import {ArrowClockwise, ArrowRepeat, StopCircle, Stop, Pencil} from "react-bootstrap-icons";
import Moment from "moment";


function PollVoting({poll}) {
    //TODO: is poll prop necesary?!

    //hooks and states
    const dispatch = useDispatch();
    const authState = useSelector(state=>state.auth);
    const pollState = useSelector(state=>state.poll);
    const [isLoading, setPageIsLoading] = useState(true);
    const [pollAnswers, setPollAnswers] = useState([]); //answers from database
    const [availableAnswers, setAvailableAnswers] = useState([]); //answers passed to subcomponent
    const [uiToggleSelection, setUiToggleSelection] = useState([]); //markings passed to subcomponent
    const [showEdit, setShowEdit] = useState(false); //show bool for modal

    //refresh
    const [refreshText, setRefreshText] = useState('Starting'); //deprecated?
    const [refreshState, setRefreshState] = useState(false);
    const initialRefreshTime = pollState.refreshTime;
    const autoRefreshReference = useRef(false); //boolean reference: on or off
    const refreshFunctions = useRef([]); //list of id
    //const abortController = new AbortController(); //TODO: check if necessary
    let isActive = true;
    if (!pollState.poll || pollState.poll.status!=='vote') {
        isActive = false
    }
    let canEdit = false;
    if (Number(authState.user.id)===1 || Number(authState.user.id)===2) {
        canEdit = true;
    }

    const fetchPollAnswers=()=>{ //request answers from api
        if (pollState.poll.id>0) {
            RestClient.PostRequest(AppUrl.GetAnswers, {id: pollState.poll.id},{bearer:authState.token})
                .then(resData=>{
                    setPollAnswers(resData);
                    setPageIsLoading(false);
                })
                .catch(()=>{});
        }
    }

    useEffect(() => { //component effect
        fetchPollAnswers();
        //clean up
        return function () {
            clearAllIntervals();
            setRefreshState(false);
        };
    },[]);

    useEffect(() => { //generate answers list for subcomponnent
        if (!isLoading) {
            mapAnswers(pollAnswers);
            dispatch(pollActions.setRefreshTime(initialRefreshTime));
            if (availableAnswers.length>0) {
                //refreshFunctions.current.push(setInterval(() =>checkPollTimer(initialRefreshTime), 5000));
            }
            if (autoRefreshReference.current) { //called from autorefresh
                setRefreshText("In " + initialRefreshTime + "s")
            }
        }
    },[pollAnswers]); //hook is called when pollAnswers changes

    const handleRefreshNow = (e) => { //handle click for manual refresh
        reloadPollDetails();
        resetAutoRefreshReference();
    }

    const handleAutoRefresh = (e) => { //handle click for manual refresh
        autoRefreshReference.current = e.target.checked;
        setRefreshState(e.target.checked);
        clearAllIntervals();
        if (e.target.checked) {
            refreshFunctions.current.push(setTimeout(() =>checkPollTimer(initialRefreshTime), 1000));
        } else {
            setRefreshText("Starting");
        }
    }

    const checkPollTimer = (localInterval=initialRefreshTime) => {
        localInterval = localInterval - 1;
        clearAllIntervals();
        console.log(localInterval);
        if (autoRefreshReference.current) {
            if (localInterval > 0) { //timer is still counting
                setRefreshText("In " + localInterval + "s");
                refreshFunctions.current.push(setTimeout(() => checkPollTimer(localInterval), 1000));
            } else {
                setRefreshText("Refreshing");
                refreshFunctions.current.push(setTimeout(() => reloadPollDetails(), 100));
                refreshFunctions.current.push(setTimeout(() => checkPollTimer(initialRefreshTime), 2000));
            }
        } else {
            resetAutoRefreshReference();
        }
    }

    const resetAutoRefreshReference=()=>{
        clearAllIntervals();
        autoRefreshReference.current = false;
        setRefreshText("Starting");
    }

    const clearAllIntervals=()=>{
        if (refreshFunctions.current.length>0) { //clear all functions
            refreshFunctions.current.map((refreshFunctionId)=>{
                clearInterval(refreshFunctionId);
            });
            refreshFunctions.current = [];
        }
    }

    const reloadPollDetails=()=>{
        setPageIsLoading(true);
        setAvailableAnswers([]);
        setUiToggleSelection([]);
        fetchPollAnswers();
    }

    const mapAnswers=(pollAnswers)=>{
        if (pollAnswers===undefined) {
            return;
        }
        const availableAnswersLocal = createAvailableAnswers();
        const uiSelection = [];
        let votesCount = 0;
        availableAnswersLocal.map((answer, index)=>{
            pollAnswers.map(pollAnswer=>{
                if (Number(pollAnswer.soffset)===index) {
                    if (uiSelection.indexOf(index)<0 && pollAnswer.checked>0) {
                        uiSelection.push(index);
                    }
                    answer.votes = pollAnswer.votes;
                    answer.voters = pollAnswer.voters;
                    if (Number(pollAnswer.checked)>0) {
                        answer.checked = true;
                    }
                }//end if
            });
            votesCount = votesCount + Number(answer.votes);
            availableAnswersLocal[index] = answer;
        });
        setAvailableAnswers(availableAnswersLocal);
        setUiToggleSelection(uiSelection);
        dispatch(pollActions.setUiSelection(uiSelection));
        dispatch(pollActions.setVotesCount(votesCount));
    }

    const createAvailableAnswers=()=> {
        const availableAnswersLocal = [];
        const pollStartDate = new Date(pollState.poll.start_date);
        const pollEndDate = new Date(pollState.poll.end_date);
        const avAnswers = Number(pollState.poll.av_answers);
        let dateWithOffset = new Date(pollState.poll.start_date);
        //console.log({pollEndDate});
        let standardAnswer = {
            counter: -1,
            checked: false,
            votes: 0,
            voters: [],
            text: '',
            date: null, //todo: is date necessary?
        }
        let dayMs = 24 * 60 * 60 * 1000;
        for (let i = 0; i < avAnswers; i++) {
            //dateWithOffset.setDate(pollStartDate.getDate() + i); //erors occured when changed from one month to another
            dateWithOffset.setTime(pollStartDate.getTime() + i * dayMs);
            if (dateWithOffset<=pollEndDate) {
                let pushAnswer = {...standardAnswer};
                pushAnswer.counter = i;
                pushAnswer.date = new Date(dateWithOffset.getTime());
                pushAnswer.text = Moment(dateWithOffset).format('dddd DD.MM.YYYY');
                availableAnswersLocal.push(pushAnswer);
            } else {
                break;
            }
        }//end for
        let pushAnswer = {...standardAnswer};
        pushAnswer.counter = availableAnswersLocal.length;
        pushAnswer.text = "Can't participate ( absence )";
        availableAnswersLocal.push(pushAnswer);
        pushAnswer = {...standardAnswer};
        pushAnswer.counter = availableAnswersLocal.length;
        pushAnswer.text = "Not sure ( active abstention )";
        availableAnswersLocal.push(pushAnswer);
        return availableAnswersLocal;
    }

    //return spinner
    if (availableAnswers.length===0) {
        return (
                <UiSpinner />
        );
    }
    const handleToggleEditMeetingModal=(e)=> {
        e.preventDefault();
        setShowEdit(!showEdit);
    }

    //return loaded component
    return (
        <Fragment>
            <Row className="justify-content-center gy-5">
                <Col xs={12} lg={6}>
                    <Alert variant={isActive?"success":"info"}
                           data-bs-theme="dark"
                           className="gx-5"
                    >
                            Voting is
                            {isActive && <Fragment> enabled </Fragment>}
                            {!isActive && <Fragment> disabled </Fragment>}
                        <Badge pill={false}
                               text="light"
                               data-bs-theme="dark"
                               key="badge-t"
                               id="votes-badge-t"
                               bg="primary"
                               className="position-absolute top-50 translate-middle end-0"
                        >{pollState.votesCount}</Badge>
                    </Alert>
                </Col>
            </Row>
            <Row className="justify-content-center gy-5">
                <Col xs={12} lg={6} >
                    <PollAnswersToggle
                        answers={availableAnswers}
                        uiToggleSelection={uiToggleSelection}
                        date={pollState.poll.start_date}
                        pollId={pollState.poll.id}
                        isActive={isActive}
                    />
                </Col>
            </Row>
            <Row className="justify-content-center gy-5">
                <Col>&nbsp;</Col>
            </Row>
            <Row className="justify-content-center gy-5">
                <Col xs={12} lg={6}>
                    <Row className="justify-content-between gy-5">
                {isActive &&
                    <Col xs={4}>
                        <Button type="button"
                                variant="outline-info"
                                data-bs-theme="dark"
                                onClick={handleRefreshNow}
                        ><ArrowClockwise/> Refresh</Button>
                    </Col>
                }
                {canEdit &&
                    <Col xs={isActive?4:12}>
                        <Button type="button"
                                variant="outline-secondary"
                                data-bs-theme="dark"
                                onClick={handleToggleEditMeetingModal}
                        ><Pencil/> Edit</Button>
                    </Col>
                }
                {isActive &&
                <Col xs={4}>
                    <ToggleButton
                        id="toggle-check"
                        type="checkbox"
                        variant="outline-warning"
                        checked={refreshState}
                        onChange={handleAutoRefresh}
                    > {!autoRefreshReference.current && <Fragment><ArrowRepeat /> Auto</Fragment>}
                        {autoRefreshReference.current && <Fragment><StopCircle /> {refreshText}</Fragment>}
                    </ToggleButton>
                </Col>
                }
                    </Row>
                </Col>
            </Row>
            <MeetingEditModal
                answers={availableAnswers}
                poll={pollState.poll}
                isActive={isActive}
                showEdit={showEdit}
                setShowEdit={setShowEdit}
                />
        </Fragment>
    );
}

export default PollVoting;