import React, {useEffect, useState, Fragment} from 'react';
import {Alert, Col, FloatingLabel, Form, Row} from "react-bootstrap";
import {createEmptyItem, getItemsRender} from "../../store/logbookSlice";
import ItemSharing from "./ItemSharing";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import {CurrencyDollar, Person, Trophy} from "react-bootstrap-icons";

function ItemForm({item, handleSubmit, handleInput,
                      products, participants, itemsRender}) {
    const [partView, setPartView] = useState(<Fragment />);

    if (!item) {
        item = createEmptyItem();
    }
    let productIdValue = item.productId;
    if (item.productId===undefined && item.product_id!==undefined) { //adaptation for database
        productIdValue = item.product_id;
    }
    if (!productIdValue) {
        productIdValue = 0;
    }
    let nameValue = item.name;
    if (!nameValue) {
        nameValue = "";
    }
    let priceValue = item.price;
    if (!priceValue && priceValue!==0) {
        priceValue = "";
    }
    if (!products) {
        products = [];
    }

    let sharesValue = item.shares;
    if (!sharesValue) {
        sharesValue = [];
    }

    const [itemId, setItemId] = useState(item.id);
    const [name, setName] = useState(nameValue);
    const [quantity, setQuantity] = useState(item.quantity);
    const [price, setPrice] = useState(priceValue);
    const [productId, setProductId] = useState(productIdValue);
    const [sharedMode, setSharedMode] = useState(item.shared);
    const [shares, setShares] = useState(sharesValue);
    const [prevItemData, setPrevItemData] = useState({name: null, price:''});
    const [localRender, setLocalRender] = useState(0);
    //const itemRef = useRef(null);

    const consoleLogIS=(logText)=> {
        logText += " : id=" + item.id+" localRender="+localRender+" ";
        if (item.quantity) {
            logText += "  item.quantity=" + item.quantity;
        }
        if (item.shares) {
            logText += "  item.shares=" + item.shares.length;
        }
        if (participants) {
            logText += "  participants=" + participants.length;
        }
        if (item.shared) {
            logText += "  item.sharedMode=" + item.shared;
        }
        console.log(logText);
    }
    //consoleLogIS("ItemForm render");
    //console.log(itemRef.current);

    useEffect(() => { //component effect
        if (itemId===undefined) {
            setItemId(0);
        }
        let operatedItem = {
            id:itemId,
            name:name,
            quantity:quantity,
            price:price,
            productId:productId,
            shared:sharedMode,
            shares:shares,
            uiShares:JSON.stringify(shares)
        };
        handleInput(operatedItem);
        //itemRef.current = operatedItem;
        //consoleLogIS("ItemForm useEffect from child to parent itemId");
        setPartView(renderViewForm());
        //console.log("operatedItem",operatedItem);
    },[name, quantity, price, productId, sharedMode, shares]);

    useEffect(() => {
        //console.log("effect component: ",localRender);
        setLocalRender(localRender+1);
        setPartView(renderViewForm());
        //consoleLogIS("ItemForm first effect");
    },[]);

    useEffect(() => { //effect for lazy products load
        if (products && products.length) {
            setLocalRender(localRender + 1);
            setPartView(renderViewForm());
        }
    },[products]);

    useEffect(() => { //effect for lazy participants load
        if (participants && participants.length) {
            setLocalRender(localRender + 1);
            setPartView(renderViewForm());
        }
    },[participants]);

/*
    useEffect(() => { //data is dirty effect
        if (item && item.quantity) {
            setItemId(item.id);
            setName(item.name);
            setQuantity(item.quantity);
            setPrice(item.price);
            setShares(item.shares);
            let productIdValue = item.productId;
            if (item.productId===undefined && item.product_id!==undefined) { //adaptation for database
                productIdValue = item.product_id;
            }
            setProductId(productIdValue);
        }
    },[]);
*/

    const handleProductChange = (e) => {
        fillProductInfo(Number(e.target.value));
        setProductId(e.target.value);
    }

    const fillProductInfo = (searchedProductId) => {
        if (searchedProductId>0) {
            let foundProduct = products.find(product => Number(product.id) === searchedProductId);
            if (foundProduct) {
                if (prevItemData.name === null) {
                    let data = {
                        name: name,
                        price: price
                    }
                    setPrevItemData(data);
                }
                setPrice(foundProduct.price);
                setName(foundProduct.nickname);
                return foundProduct.id;
            }
        } else {
            if (prevItemData.name!==null) {
                setName(prevItemData.name);
                setPrice(prevItemData.price);
            }
        }
        return 0;
    }


    const handleChangePrice=(e)=> {
        const re = /^[0-9\b]+$/;
        if (re.test(e.target.value)) {
            if (Number(e.target.value)>=0 && Number(e.target.value)<=99999) {
                setPrice(Number(e.target.value));
            }
        }
        if (e.target.value === '') {
            setPrice(0);
        }
    }

    const handleFocusAutoSelect = (event) => event.target.select();

    const handleSearchOnSelect = (sItem) => {
        let foundProductId = fillProductInfo(Number(sItem.id));
        console.log({foundProductId});
        if (foundProductId>0) {
            setProductId(foundProductId);
        }
    }

    const handleSearchOnFocus = (event) => {
        event.target.select();
    }

    const handleFormatSearchResult = (sItem) => {
        return (
            <Fragment>
                <span className={"search-product-result-list fs-6"}>
                    <span className={"d-grid m-1"}>{sItem.nickname} ${sItem.price}</span>

                    <small className={"d-grid m-1 text-white-50"}>{sItem.name}</small>
                </span>
            </Fragment>
        )
    }


    const renderViewForm=()=> {
        const fuseOptions={
            shouldSort: true,
            threshold: 0.5,
            location: 3,
            distance: 700,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            showItemsOnFocus: true,
            keys: [
                 "name",
                 "nickname",
               ]
        }
        const searchStyling={
            backgroundColor: "#212529",
            border: "1px solid #495057",
            borderRadius: "0.375rem",
            color: "rgb(222, 226, 230)",
            lineColor: "rgba(222, 226, 230, 0.5)",
            placeholderColor: "rgb(222, 226, 230)",
            boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
            hoverBackgroundColor: "#343a40",
            height: "56px",
            fontSize: "16px",
            zIndex: 1000,
            maxHeight: 300,
            //searchIconMargin: "10px 12px 0 11px",
            //clearIconMargin: "10px 0 8px 12px",
         };

        return (
            <Form onSubmit={handleSubmit}>
                <Row className="gy-2">
                    <Col className="p-0 px-1" xs={12}>
                        <ReactSearchAutocomplete
                            placeholder="Search for existing product"
                            maxLength={20}
                            maxResults={8}
                            inputDebounce={50}
                            items={products}
                            fuseOptions={fuseOptions}
                            onSelect={handleSearchOnSelect}
                            onFocus={handleSearchOnFocus}
                            formatResult={handleFormatSearchResult}
                            className={"search-product-box"}
                            styling={searchStyling}
                        />
                    </Col>
                    <Col className="p-0 px-1" xs={9} md={10}>
                        <FloatingLabel data-bs-theme="dark"
                                       label="Item short name *">
                            <Form.Control
                                placeholder="Pilsner"
                                value={name}
                                required={true}
                                onFocus={handleFocusAutoSelect}
                                onChange={(e)=>setName(e.target.value)}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col className="p-0 px-1" xs={3} md={2} >
                        <FloatingLabel data-bs-theme="dark"
                                       label="Qty *"
                        >
                            <Form.Select
                                value={quantity}
                                required={true}
                                onChange={(e)=>setQuantity(Number(e.target.value))}
                            >
                                {Array.from({length:9},(_,i)=>i+1).map(
                                    (num)=>(
                                        <option value={num} key={num}>{num}</option>
                                    )
                                )}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col xs={9} md={10} className="p-1 py-0">
                        <FloatingLabel
                            controlId="floatingSelectGrid"
                            label="Known or existing product"
                            data-bs-theme="dark"
                        >
                            <Form.Select
                                aria-label="Floating label select example"
                                value={productId}
                                onChange={handleProductChange}
                            >
                                <option value={0}>None - N/A</option>
                                {products.map(product=>(
                                    <option value={product.id}
                                            key={product.id}
                                    >{product.nickname}</option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col className="p-1 py-0" xs={3} md={2} >
                        <FloatingLabel data-bs-theme="dark"
                                       label="Price">
                            <Form.Control
                                placeholder="20"
                                value={price}
                                onFocus={handleFocusAutoSelect}
                                onChange={handleChangePrice}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>

                <ItemSharing
                    key={"is-"+item.id+"-"+itemsRender}
                    setSharedMode={setSharedMode}
                    setShares={setShares}
                    item={item}
                    participants={participants}
                />
            </Form>
        );
    }//end function

    return (<Fragment>{partView}</Fragment>);
}

export default ItemForm;