import React, {Fragment, useEffect, useState} from 'react';
import {Container, Row, Col, Alert} from 'react-bootstrap';
import '../../asset/css/birt.css';
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import PollVoting from "../PollDetails/PollVoting.jsx";
import Moment from "moment";
import {useSelector, useDispatch} from "react-redux";
import {getPollIsLoading, pollActions} from "../../store/pollSlice";
import UiSpinner from "../UI/UiSpinner";
import PollMeeting from "../PollDetails/PollMeeting";

function Poll() {
    const dispatch = useDispatch();
    const authState = useSelector(state=>state.auth);
    const pollState = useSelector(state=>state.poll);
    const pollIsLoading = useSelector(getPollIsLoading);
    let showAlert = true; //displays the alert component
    let alertView = null; //view for alert
    let votingView = null; //view

    useEffect(() => { //component effect
        dispatch(pollActions.setIsLoading(true));
    },[]);

    useEffect(() => { //component effect
        if (pollIsLoading) {
            RestClient.GetRequest(AppUrl.LatestActivePoll, {bearer:authState.token})
                .then(resData=>{
                    if (resData!==undefined && resData.poll!==undefined) {
                        dispatch(pollActions.setPoll(resData.poll));
                    }
                })
                .finally(()=>{
                    dispatch(pollActions.setIsLoading(false));
                });
        }
    },[pollIsLoading]);


    if (pollState.isLoading) {
        showAlert = false;
        votingView = (<UiSpinner message="Data is loading" />);
    } else {
        if (pollState.poll) {
            showAlert = false;
            votingView = (<PollVoting poll={pollState.poll}/>);
        } else {
            alertView = (<Alert variant="danger" data-bs-theme="dark">
                There are no active polls!
            </Alert>)
            votingView = (<Fragment></Fragment>);
        }
    }

    return (
        <Container className="container overflow-hidden">
            {showAlert &&
            <Row className="justify-content-center gy-5">
                <Col lg={8} xl={7}>
                    {alertView}
                </Col>
            </Row>}
            <PollMeeting poll={pollState.poll}/>
            {votingView}
        </Container>
    );
}

export default Poll;