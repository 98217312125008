import React, {Fragment, useEffect, useState} from 'react';
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import UiSpinner from "../UI/UiSpinner";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Container, Table, Alert} from "react-bootstrap";
import Moment from "moment";
import CheckIndividual from "../Check/CheckIndividual";
import CheckShared from "../Check/CheckShared";
import CheckTop from "../Check/CheckTop";
import {getParticipants, logbookActions} from "../../store/logbookSlice";

function MeetingCheck() {
    const dispatch = useDispatch();
    const authState = useSelector(state=>state.auth);
    const participants = useSelector(getParticipants);
    const [isLoading, setIsLoading] = useState(null);
    const [meeting, setMeeting] = useState(null);
    const [sharedCheck, setSharedCheck] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        RestClient.GetRequest(AppUrl.LatestMeeting, {bearer:authState.token})
            .then(resData=>{
                if (resData!==undefined) {
                    setMeeting(resData.meeting);
                    fetchParticipantsFromMeeting(resData.meeting);
                    //console.log(resData);
                    setIsLoading(false);
                }
            });
    },[]);

    useEffect(() => { //effect on meeting update
        if (meeting && meeting.id) {
            RestClient.PostRequest(AppUrl.GetSharedCheck, {id: meeting.id},{bearer: authState.token})
                .then(resData => {
                    if (resData) {
                        setSharedCheck(resData);
                    }
                });
        }
    },[meeting]);

    const fetchParticipantsFromMeeting=(localMeeting)=> { //request answers from api
        if (localMeeting && localMeeting.logbooks) {
            let participants = localMeeting.logbooks.map(item=>{
                return {
                    id:item.user_id,
                    name:item.user_name
                }
            });
            dispatch(logbookActions.setParticipants(participants));
        }
    }

    if (isLoading) {
        return (<UiSpinner />);
    }

    if (!meeting || !meeting.logbooks) {
        return (<UiSpinner />);
    }
    return (
        <Container className="container overflow-hidden">
            <Row className="justify-content-center">
                <Col lg={8} xl={7}>
                    {meeting && <Alert variant="primary" className="border border-light-subtle" data-bs-theme="dark">
                        <small>{meeting.location} - {Moment(meeting.result_dt).format('DD.MM.YYYY HH:mm')}
                            {meeting.total && meeting.total>0 &&<Fragment><br />Check has a total of {meeting.total}</Fragment>}
                        </small>
                    </Alert>}
                </Col>
                <Col lg={8} xl={7}>
                    <CheckTop meeting={meeting}/>
                </Col>
                <Col lg={8} xl={7}>
                    <Alert variant="primary" className="border border-light-subtle lh-05" data-bs-theme="dark">
                        <small>Individual items</small>
                    </Alert>
                    <CheckIndividual meeting={meeting}/>
                </Col>
                {participants && participants.length &&
                    <Col lg={8} xl={7}>
                        {sharedCheck && sharedCheck.shares && sharedCheck.shares.length &&
                            <Alert variant="primary" className="border border-light-subtle lh-05" data-bs-theme="dark">
                                <small>Shared items</small>
                            </Alert>
                        }
                        <CheckShared
                            participants={participants}
                            sharedCheck={sharedCheck}/>
                    </Col>
                }
            </Row>
        </Container>
    );
}

export default MeetingCheck;