import React, {Fragment} from 'react';
import {Row, Col, Card, Alert } from 'react-bootstrap';
import Moment from "moment";

function PollMeeting({poll}) {
    if (!poll) {
        return (<Fragment></Fragment>);
    }

    let hasMeeting = true;
    if (!poll.meeting) {
        hasMeeting = false;
    }

    let isActive = true;
    if (poll.status==='fail') {
        isActive = false;
        if (hasMeeting) { //impossible case
            hasMeeting = false;
        }
    }

    if (isActive && !hasMeeting) {
        return (<Fragment></Fragment>);
    }

    return (
        <Row className="justify-content-center gy-5 pb-3">
            <Col lg={6} xl={6}>
                <Card className="text-center"
                      border={isActive?"success":"danger"}
                      data-bs-theme="dark">
                    <Card.Header>Poll has ended</Card.Header>
                    {hasMeeting &&
                    <Card.Body>
                        <Card.Text className="fs-6">
                            A meeting has been scheduled for {poll.meeting.participants} participants
                        </Card.Text>
                            <Alert variant="success" className="lh-05" data-bs-theme="dark">
                                {Moment(poll.meeting.result_dt).format('DD.MM.YYYY HH:mm')}
                                <hr />
                                {poll.meeting.location}
                            </Alert>
                        <Card.Text>
                            {poll.meeting.description}
                        </Card.Text>
                    </Card.Body>}
                    {!isActive &&
                    <Card.Body>
                        <Card.Text>
                            The quorum was not met
                        </Card.Text>
                    </Card.Body>}
                </Card>
            </Col>
        </Row>
    );
}

export default PollMeeting;