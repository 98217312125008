import React, {Fragment, useEffect, useState} from 'react';
import {Col, Row, Container, Table, Alert, Badge} from "react-bootstrap";
import UiTableHeader from "../UI/UiTableHeader";
import {CashCoin, CurrencyDollar, PersonCheck} from "react-bootstrap-icons";

function CheckShared({sharedCheck, participants}) {
    const [headerView, setHeaderView] = useState(<Fragment />);
    const [bodyView, setBodyView] = useState(<Fragment />);
    const [mappedHeader, setMappedHeader] = useState([]);
    const [totalGeneral, setTotalGeneral] = useState(0);

    const getParticipantName=(participantId)=> {
        //console.log("participants", participants, participantId);
        let foundPart = participants.find((pUser) => Number(pUser.id) === Number(participantId));
        if (foundPart) {
            return foundPart.name;
        } else {
            return "";
        }
    }

    const getParticipantPositionInHeader=(participantId)=> {
        let foundMapping = mappedHeader.find((mapItem) => Number(mapItem.wid) === Number(participantId));
        if (foundMapping) {
            return foundMapping.index;
        }
        return null;
    }

    const getParticipantIdFromPositionInHeader=(mappedIndex)=> {
        let foundMapping = mappedHeader.find((mapItem) => Number(mapItem.index) === Number(mappedIndex));
        if (foundMapping) {
            return foundMapping.wid;
        }
        return null;
    }

    const mapParticipantToPositionInHeader=(participantId, index)=>{
        if (!participantId) {
            return;
        }
        let foundMapping = getParticipantPositionInHeader(participantId);
        if (!foundMapping) {
            setMappedHeader(
                [...mappedHeader, {wid:participantId, index:index+1}]
            );
        }
    }

    const renderHeaderView=()=>{
        if (sharedCheck && sharedCheck.shares) {
            let tView = sharedCheck.shares.map((item,index)=>{
                //console.log("renderHeaderView ",item, index);
                mapParticipantToPositionInHeader(item.wid, index);
                return(
                    <UiTableHeader
                        key={"cs-"+index}
                        text={getParticipantName(item.wid)}
                        icon={<PersonCheck className="fs-3" />}
                        badgeText={item.collect.length+1}
                    />
                );
            });
            setHeaderView(tView);
            //console.log(mappedHeader);
            renderBodyView();
        }
    }


    const getDebt=(fromId,mappedIndex)=> {
        let toId = getParticipantIdFromPositionInHeader(mappedIndex);
        if (!toId) {
            return  0;
        }
        let foundSharer = sharedCheck.shares.find((pUser) => Number(pUser.wid) === Number(toId));
        if (!foundSharer) {
            return 0;
        }
        let foundDebt = foundSharer.collect.find((debtItem) => Number(debtItem.wid) === Number(fromId));
        if (!foundDebt) {
            return 0;
        }
        return foundDebt.debt;
    }

    const getSelfTotal=(userId,mappedIndex)=> {
        let toId = getParticipantIdFromPositionInHeader(mappedIndex);
        if (!toId || Number(userId) !== Number(toId)) { //userId and toId must be equal
            return  0;
        }
        let foundSharer = sharedCheck.shares.find((pUser) => Number(pUser.wid) === Number(userId));
        if (!foundSharer) {
            return 0;
        }
        return foundSharer.self;
    }


    const renderBodyView=()=>{
        if (sharedCheck && sharedCheck.totals) {
            let total = 0;
            //console.log(sharedCheck.shares);
            let tView = sharedCheck.totals.map((rowItem,rowIndex)=>{
                //let sharesTotal = getSharesTotal(rowItem.wid);
                total = total + Number(rowItem.total);
                let dinamicCols = [];
                for (let i=1;i<=sharedCheck.shares.length;i++) { //starts from 1
                    let debt = getDebt(rowItem.wid, i);
                    if (!debt) {
                        debt = getSelfTotal(rowItem.wid, i);
                        if (debt) {
                            debt = "~ " + debt;
                        } else {
                            debt = "-";
                        }
                    }//end if !debt

                    dinamicCols.push(<td key={"trc-"+i} className="">{debt}</td>);
                }//end for
                return (<tr key={"tr-"+rowIndex}>
                    <td className="">{getParticipantName(rowItem.wid)}</td>
                    {dinamicCols}
                    <td className="">~ {rowItem.total}</td>
                </tr>);
            });
            setBodyView(tView);
            setTotalGeneral(total);
            //console.log({totalGeneral});
        }
    }

    useEffect(() => {
        renderHeaderView();
    },[sharedCheck, participants, mappedHeader]);

    if (!sharedCheck || !sharedCheck.totals
        || !sharedCheck.totals.length
        || !sharedCheck.shares.length
        || !participants
        || !participants.length
        || !mappedHeader
        || !mappedHeader.length
    ) {
        return (<Fragment />);
    }
    return (
        <Table striped
               bordered
               hover
               data-bs-theme="dark"
               variant="dark"
               className="table-sm"
        >
            <thead>
            <tr >
                <th>Shared<br />with \ by</th>
                {headerView}
                <UiTableHeader
                    text={<Fragment>Total</Fragment>}
                    icon={<CurrencyDollar className="fs-3" />}
                />
            </tr>
            </thead>
            <tbody>
            {bodyView}
            {sharedCheck.shares.length>0 &&
                <tr>
                <td>Total</td>
                    {sharedCheck && sharedCheck.shares &&
                        sharedCheck.shares.map((item,index)=>(<td key={"td-"+index}>{item.total} for {item.collect.length+1}</td>))
                    }
                <td>{totalGeneral}</td>
                </tr>}
            </tbody>
        </Table>
    );
}

export default CheckShared;