import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    poll:null,
    pollId: 0,
    uiSelection: [], //markings passed to subcomponent
    votesCount:0,
    refreshTime: 9,
    isLoading: true,
}

export const pollSlice = createSlice({
    name:'poll',
    initialState,
    reducers: {
        setPoll(state, action) {
            state.poll = action.payload;
            state.pollId = action.payload.id;
        },
        setUiSelection(state, action) {
            state.uiSelection = action.payload;
            state.loading = false;
        },
        setVotesCount(state, action) {
            state.votesCount = action.payload;
        },
        modifyVotesCount(state, action) {
            state.votesCount = Number(state.votesCount) + Number(action.payload);
        },
        setRefreshTime(state, action) {
            state.refreshTime = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
    },//end reducers
});

export const pollActions = pollSlice.actions;
export default pollSlice.reducer;
export const getPollIsLoading = (state) => state.poll.isLoading;