import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function UiModal(props) {
    //const [fullscreen, setFullscreen] = useState(props.fullscreen);
    //const [show, setShow] = useState(props.show);

    return (
        <Modal show={props.show}
               data-bs-theme="dark"
               onHide={() =>props.setShow(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.content}</Modal.Body>
            <Modal.Footer>
                {props.children}
            </Modal.Footer>
        </Modal>
    );
}

export default UiModal;