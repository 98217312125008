import React, { Fragment, useEffect} from 'react';
import {authActions} from "../../store/authSlice";
import {useDispatch} from "react-redux";

function SignOut(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(authActions.signOut());

    },[]);
    return (
        <Fragment>
            You have been signed out
        </Fragment>
    );
}

export default SignOut;