import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userId: 0,
}

export const profileSlice = createSlice({
    name:'profile',
    initialState,
    reducers: {
        testProfile(state, action) {
            state.userId = action.payload.userId;
            console.log("userReducer:");
            console.log(JSON.stringify(state));
        }
    },//end reducers
});

export const profileActions = profileSlice.actions;
export default profileSlice.reducer;