import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Footer from "../components/Footer/Footer";

class HomePage extends Component {
    render() {
        return (
            <Fragment>
                <div className="backgroundSettings">
                    <TopNavigation />
                    <div className="servicesOverlay">
                        <div className="text-center">
                        <iframe id="ytplayer" type="text/html" height="640" title="Hai doamne la BIRT"
                                src="https://www.youtube.com/embed/C-uI9giVxsU?autoplay=0"
                                frameBorder="0"></iframe>
                        </div>
                    </div>
                    <Footer />
                </div>
            </Fragment>
        );
    }
}

export default HomePage;