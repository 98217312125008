import React, {useEffect, useState, useRef, useContext, Fragment} from 'react';
import ItemForm from "./ItemForm";
import {Button, Col, Row, Accordion, Badge} from "react-bootstrap";
import {Save, Trash3, Person, Cart2, CurrencyDollar, CashCoin, PersonCheck} from "react-bootstrap-icons";
import UiBadgeIcon from "../UI/UiBadgeIcon";

function ItemsList({items, products, participants, itemsRender, itemsAreLoading,
                       setEditedItem,
                       handleAddItemSubmit, handleEditItemSubmit, handleDeleteItemSubmit}) {

    const [compView, setCompView] = useState(<Fragment />);
    const [wasClicked, setWasClicked] = useState(false);

    useEffect(() => {
        setCompView(renderViewList());
        //console.log("ItemsList effect ");
    },[]);

    useEffect(() => {
        setCompView(renderViewList());
    },[items, participants, wasClicked]);

    const handleEdit=(e)=>{
        setWasClicked(true);
        handleEditItemSubmit(e);
    }

    //console.log("ItemsList render itemsRender=", itemsRender, "items=",items.length," participants=",participants.length, "products=",products.length);
    const renderViewList=()=>{
        return items.map(item=>{
            let countShares = 0;
            if (item.shares && item.shares.length>0) {
                countShares = item.shares.length + 1; //add the person who shared the item
            }
            return (
            <Accordion.Item key={"ia-"+item.id+"-"+itemsRender} eventKey={item.id}>
                <Accordion.Header>
                    <div className="text-start w-50 d-inline-block">
                        {item.name}
                    </div>
                    <div className="text-end justify-content-end w-50 px-1 d-inline-block">
                        {countShares > 0 &&
                            <UiBadgeIcon
                                icon={<PersonCheck className="fs-4"/>}
                                text={countShares}
                            />
                        }
                        <UiBadgeIcon
                            icon={<Cart2 className="fs-4" />}
                            text={item.quantity}
                        />
                        <UiBadgeIcon
                            icon={<CurrencyDollar className="fs-4" />}
                            text={item.quantity * item.price}
                        />
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <ItemForm key={"if-"+item.id+"-"+itemsRender}
                              renderId={"if-"+item.id+"-"+itemsRender}
                              item={item}
                              handleSubmit={handleAddItemSubmit}
                              handleInput={setEditedItem}
                              products={products}
                              participants={participants}
                              itemsAreLoading={itemsAreLoading}
                              itemsRender={itemsRender}
                    />
                    <Row className="gy-2 py-2 px-1 justify-content-between">
                        <Button as={Col}
                                xs={5}
                                className="p-2"
                                variant={wasClicked?"outline-secondary":"outline-warning"}
                                disabled={wasClicked}
                                onClick={handleEdit}
                        >
                            <Save /> Save
                        </Button>
                        <Button as={Col}
                                xs={5}
                                className="p-2"
                                variant={wasClicked?"outline-secondary":"outline-danger"}
                                disabled={wasClicked}
                                onClick={handleDeleteItemSubmit}
                        >
                            <Trash3 /> Delete
                        </Button>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        )});
    }//end render

    return (
       <Fragment>
            {compView}
       </Fragment>
    );
}

export default ItemsList;