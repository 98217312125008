import React, {useEffect, useState, useRef, useContext, Fragment} from 'react';
import ItemForm from "./ItemForm";
import {Button, Col, Row, Accordion, AccordionContext} from "react-bootstrap";
import {PlusCircle, Save, Trash3} from "react-bootstrap-icons";
import {useDispatch, useSelector} from "react-redux";
import {
    getItemsRender,
    getLogbooksRender,
    getProducts,
    createEmptyItem,
    logbookActions, getParticipants, getItemsAreLoading
} from "../../store/logbookSlice";
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import ItemsSummary from "./ItemsSummary";
import ItemsList from "./ItemsList";
import UiSpinner from "../UI/UiSpinner";
import AddItemAccordion from "./AddItemAccordion";

function ItemsOperator({selectedLogbookId}) {
    const dispatch = useDispatch();
    const authState = useSelector(state=>state.auth);
    const logbookState = useSelector(state=>state.logbook);

    const products = useSelector(getProducts);
    const participants = useSelector(getParticipants);
    const itemsRender = useSelector(getItemsRender);
    const itemsAreLoading = useSelector(getItemsAreLoading);

    const [viewCompList, setViewCompList] = useState(<Fragment />);
    const [activeKey, setActiveKey] = useState(0); //opened accordion item
    //states for edit
    const [isAddingItem, setIsAddingItem] = useState(false); //disables button and operation
    const [addedItem, setAddedItem] = useState(createEmptyItem());
    //references and states for edit
    const [items, setItems] = useState([]);
    const editedKey = useRef(0);
    const setEditedKey=(item)=>{
        editedKey.current = item;
    }
    const editedItem = useRef(null); //edit operation item
    const setEditedItem=(item)=>{
        editedItem.current = item;
    }


    useEffect(() => { //itemsRender & selectedLogbookId effect
        fetchLogbookItems();
    },[itemsRender, selectedLogbookId]);

    useEffect(() => { //items effect
        fetchLogbookParticipants();
    },[items, selectedLogbookId]);

    useEffect(() => { //items effect
        setViewCompList(renderViewList());
    },[]);

    useEffect(() => { //items effect
        setViewCompList(renderViewList());
    },[itemsAreLoading, items, participants, products, selectedLogbookId, editedItem, activeKey]);

/*
    useEffect(() => { //active key changes effect
        console.log("effect activeKey", {activeKey});
        if (activeKey) {
            items.filter(item => Number(item.id) === Number(activeKey))
                .map(foundItem => {
                    setEditedItem(foundItem);
                    setEditOpItem(foundItem);
                    //setActiveKey(e);
                    console.log("effect activeKey was set", foundItem.id, activeKey);
                });
        }
    },[activeKey]);
*/

    const dispatchEmptyLogbookActions=()=>{
        dispatch(logbookActions.setItems([]));
        setItems([]);
        setActiveKey(0); //add accordion will be opened
    }

    const sortItems=(sortedItems)=>{
        sortedItems.sort((a,b)=>{
            let aLength = a.shares ? a.shares.length : 0;
            let bLength = b.shares ? b.shares.length : 0;
            if (aLength === bLength) {
                return (a.id > b.id ? -1 : 1);
                //return (a.updated_at > b.updated_at ? -1 : 1);
            }
            return (aLength < bLength ? -1 : 1);
        });
        return sortedItems;
    }

    const fetchLogbookItems=()=>{ //request answers from api
        let logbookIsEmpty = false;
        if (selectedLogbookId && selectedLogbookId>0) {
            dispatch(logbookActions.setItemsAreLoading(true));
            RestClient.PostRequest(AppUrl.GetItems, {id: selectedLogbookId},{bearer:authState.token})
                .then(resData=>{
                    if (!resData.items || resData.items.length===0) {
                        dispatchEmptyLogbookActions();
                    } else {
                        let sortedItems = sortItems(resData.items); //shared items will be listed last
                        setItems(sortedItems);
                    }//end if
                })
                .catch(()=>{
                    dispatchEmptyLogbookActions();
                })
                .finally(()=>{
                    dispatch(logbookActions.setItemsAreLoading(false));
                    //fetchLogbookParticipants();
                    //console.log("----------------------- set fetchLogbookParticipants ends here");
                });
        } else {
            dispatch(logbookActions.setItemsAreLoading(false));
            dispatchEmptyLogbookActions();
        }
    }//end

    const fetchLogbookParticipants=()=> { //request answers from api
        if (selectedLogbookId && selectedLogbookId>0) {
            RestClient.PostRequest(AppUrl.GetParticipantsByLogbook, {id: selectedLogbookId},{bearer:authState.token})
                .then(resData=>{
                    dispatch(logbookActions.setParticipants(resData.participants));
                }).catch(()=>{

            });
        } else {
            dispatch(logbookActions.setParticipants([]));
        }
    }

    const handleAddItemSubmit=(e)=>{
        e.preventDefault();
        if (addedItem.id>0 || isAddingItem) {
            //console.log("no data was defined for add", addedItem);
            return;
        }
        if (addedItem.id===0 && addedItem.id===activeKey) {
            if (addedItem.name && addedItem.quantity) {
                //console.log("ItemsList operated handleAddItemSubmit ", addedItem);
                setIsAddingItem(true);
                let mergedItem = {...addedItem,...{
                        product_id: addedItem.productId,
                        ui_shares: addedItem.uiShares,
                        logbook_id: selectedLogbookId,
                    }};
                //update UI
                setActiveKey(null);
                setAddedItem(createEmptyItem());
                RestClient.PostRequest(AppUrl.AddItem, mergedItem, {bearer: authState.token})
                    .then(resData => {
                        if (selectedLogbookId>0) { //render only items
                            dispatch(logbookActions.increaseItemsRender());
                        } else { //render logbooks and items also
                            dispatch(logbookActions.setSelectedLogbookId(resData.logbook_id));
                            dispatch(logbookActions.increaseLogbooksRender());
                        }
                    })
                    .finally(()=>{
                        setIsAddingItem(false);
                    });
            } else {
                //console.log("ItemsList operated handleAddItemSubmit missing name or qty ", addedItem);
            }
        }
    }

    const handleEditItemSubmit=(e)=>{
        //console.log("handleEditItemSubmit editedItem=",editedItem.current.id,"editedKey=", editedKey.current,"activeKey=", activeKey);
        e.preventDefault();
        if (!editedItem.current || !editedItem.current.id
            || Number(editedItem.current.id)!==Number(editedKey.current)
            || Number(editedItem.current.id===0)) {
            setActiveKey(null);
            return;
        }
        let foundItemIndex = items.findIndex((item)=>item.id===editedKey.current);
        if (foundItemIndex>=0) {
            let mergedItem = {...editedItem.current,...{
                    product_id: editedItem.current.productId,
                    ui_shares: editedItem.current.uiShares,
                }};
            //replace the item in UI
            let localItems = Array.from(items);
            localItems[foundItemIndex] = mergedItem;
            setItems(sortItems(localItems));
            //close the accordion
            setActiveKey(null);
            //post the item
            RestClient.PostRequest(AppUrl.UpdateItem, mergedItem, {bearer: authState.token})
                .then(resData => {
                    dispatch(logbookActions.increaseItemsRender());
                    setActiveKey(null);
                })
                .catch(()=>{});
        }//end if
    }//end handleEditItemSubmit

    const handleDeleteItemSubmit=(e)=>{
        e.preventDefault();
        if (!editedItem.current || !editedItem.current.id
            || Number(editedItem.current.id)!==Number(editedKey.current)
            || Number(editedItem.current.id===0)) {
            setActiveKey(null);
            return;
        }

        if (!editedItem.current.logbook_id || Number(editedItem.current.logbook_id) != Number(selectedLogbookId)) { //an item will always be part of a logbook
            setActiveKey(null);
            return;
        }
        RestClient.PostRequest(AppUrl.RemoveItem, editedItem.current, {bearer: authState.token})
            .then(resData => {
                let newItems = items.filter((mItem)=>Number(mItem.id) !== Number(editedItem.id));
                setItems(newItems);
                dispatch(logbookActions.setItems(newItems));
                setActiveKey(null);
                dispatch(logbookActions.increaseItemsRender());
            })
            .catch(()=>{});
    }

    const onSelectItem=(e)=>{
        //console.log("ItemsList onSelectItem called");
        if (e===undefined || e===null || e===activeKey) { //collapse
            //console.log("ItemsList onSelectItem collapse ",e, activeKey);
            setActiveKey(null); //collapse all
            return;
        }
        //console.log("ItemsList onSelectItem expand ",e, activeKey);
        if (e===0) { //expand add
            setAddedItem(createEmptyItem());
            setActiveKey(e);
        } else { //search for item
            items.filter(item=>Number(item.id)===Number(e))
                .map(foundItem=>{ //expand item
                    //console.log("onSelectItem: active key is ",activeKey," and will be set to ",e);
                    setEditedKey(e); //for edit
                    setActiveKey(e); //for UI
                    setEditedItem(foundItem);
                });
        }
    }

    const renderViewList=()=>{
        return (
            <ItemsList key={"il-"+itemsRender}
                       items={items}
                       products={products}
                       participants={participants}
                       itemsRender={itemsRender}
                       itemsAreLoading={itemsAreLoading}
                       setEditedItem={setEditedItem}
                       handleAddItemSubmit={handleAddItemSubmit}
                       handleEditItemSubmit={handleEditItemSubmit}
                       handleDeleteItemSubmit={handleDeleteItemSubmit}
            />

        );
    }

    if (logbookState.logbookIsLoading) {
        return (<UiSpinner message="Existing logbooks are loading" />);
    }

    if (logbookState.itemsAreLoading) {
        return (<UiSpinner message="Logbook items are loading" />);
    }

    return (
        <Row className="mx-1 my-0 justify-content-center">
            <Col xs={12}>
                <ItemsSummary
                    logbookItems={items}
                    selectedLogbookId={selectedLogbookId}
                />
            </Col>
            <Col xs={12} className="m-0 p-0">
                <Accordion defaultActiveKey={0}
                           activeKey={activeKey}
                           onSelect={onSelectItem}
                           data-bs-theme="dark">
                    <AddItemAccordion
                        key={"iacc-0-"+itemsRender}
                        addedItem={addedItem}
                        itemsRender={itemsRender}
                        handleAddItemSubmit={handleAddItemSubmit}
                        setAddedItem={setAddedItem}
                        products={products}
                        participants={participants}
                        isAddingItem={isAddingItem}
                    />
                    {false &&
                        <Accordion.Item key={"ia-0-"+itemsRender} eventKey={0}>
                            <Accordion.Header>Add new item</Accordion.Header>
                            <Accordion.Body>
                                <ItemForm item={addedItem}
                                          handleSubmit={handleAddItemSubmit}
                                          handleInput={setAddedItem}
                                          products={products}
                                />
                                <Row className="gy-2 py-2 px-1 justify-content-between">
                                    <Button as={Col}
                                            xs={12}
                                            disabled={isAddingItem}
                                            className="p-2"
                                            variant={isAddingItem?"outline-secondary":"outline-success"}
                                            onClick={handleAddItemSubmit}
                                    >
                                        <PlusCircle /> Add
                                    </Button>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    }
                    {viewCompList}
                </Accordion>
            </Col>
        </Row>
    );
}

export default ItemsOperator;