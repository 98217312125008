import React, {Fragment, useState, useEffect} from 'react';
import {Form, FloatingLabel, Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    getLogbookIsLoading,
    getMeetings,
    getLogbooks,
    getLogbooksRender,
    getSelectedLogbook,
    logbookActions
} from "../../store/logbookSlice";
import {Save, X} from "react-bootstrap-icons";
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import UiModal from "../UI/UiModal";
import Moment from "moment";

function LogbookEditModal({selectedLogbookId, showEdit, setShowEdit}) {
    const dispatch = useDispatch();
    const authState = useSelector(state=>state.auth);
    const logbookIsLoading = useSelector(getLogbookIsLoading);
    const selectedLogbook = useSelector(getSelectedLogbook);
    const meetings = useSelector(getMeetings);
    const [name,setName] = useState('');
    const [meeting,setMeeting] = useState('');

    useEffect(() => { //component effect
        if (!logbookIsLoading && selectedLogbook && selectedLogbook.name) {
            setName(selectedLogbook.name);
            if (selectedLogbook.meeting_id) {
                setMeeting(selectedLogbook.meeting_id);
            } else {
                setMeeting(0);
            }
        }
    },[selectedLogbookId, logbookIsLoading]);

    if (!selectedLogbook || !name) {
        return (<Fragment></Fragment>);
    }

    const handleEditSubmit = (e) => {
        e.preventDefault();
        if (selectedLogbookId && name && name.length>0) {
            RestClient.PostRequest(AppUrl.UpdateLogbook,
                {
                    id: selectedLogbook.id,
                    name: name,
                    meeting_id: meeting,
                    },
                {bearer: authState.token})
                .then(resData => {
                    setShowEdit(false);
                    dispatch(logbookActions.increaseLogbooksRender());
                    dispatch(logbookActions.setSelectedLogbookId(resData.logbook_id));
                }).catch(()=>{
                    setShowEdit(false);
                    dispatch(logbookActions.increaseLogbooksRender());
            });
        }
    }

    const handleMeetingChange = (e) => {
        setMeeting(e.target.value);
    }

    const contentFormView = (
        <Fragment>
            <Form.Group className="mb-3" controlId="inputName">
                <FloatingLabel
                    controlId="inputName"
                    label="Name *"
                    data-bs-theme="dark"
                >
                    <Form.Control
                        placeholder="Logbook name"
                        value={name}
                        required={true}
                        onChange={(e)=>setName(e.target.value)}
                    />
                </FloatingLabel>
            </Form.Group>
            <Form.Group className="mb-0" controlId="selectMeeting">
                <FloatingLabel
                    controlId="selectMeeting"
                    label="Select meeting"
                    data-bs-theme="dark"
                >
                    <Form.Select
                        data-bs-theme="dark"
                        onChange={handleMeetingChange}
                        value={meeting}
                    >
                        <option key="0" value={0}>Not attached to a meeting</option>
                        {meetings.map((resData) => (
                            <option key={resData.id} value={resData.id}>{resData.location} - {
                                Moment(resData.result_dt).format('DD.MM.YYYY HH:mm')}</option>
                        ))}
                    </Form.Select>
                </FloatingLabel>
            </Form.Group>
        </Fragment>
    );

    return (
        <Fragment>
            <UiModal
                show={showEdit}
                setShow={setShowEdit}
                title="Edit"
                content={contentFormView}
            >
                <Button
                    variant="outline-warning"
                    onClick={handleEditSubmit}
                >
                    <Save /> Save
                </Button>
                <Button
                    variant="outline-secondary"
                    onClick={(e)=>setShowEdit(false)}
                >
                    <X /> Cancel
                </Button>
            </UiModal>
        </Fragment>
    );
}

export default LogbookEditModal;