import React, {Fragment} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {Trash3, Pencil, Copy} from "react-bootstrap-icons";

function LogbookMenu(props) {

    return (
        <Fragment>
            <Col xs={4} className="fw-normal p-0 text-start">
                <Button variant="outline-light"
                        disabled={props.selectedLogbookId<=0}
                        onClick={props.selectedLogbookId>0?props.handleEdit : null}
                >
                    <Pencil /><span className=""> Edit</span>
                </Button>
            </Col>
            <Col className="fw-normal p-0 text-center col-xs-auto">
                <Button variant="outline-danger"
                        disabled={props.selectedLogbookId<=0}
                        onClick={props.selectedLogbookId>0?props.handleRemove : null}
                >
                    <Trash3 />
                    <span className="d-md-none d-lg-inline-block"> Delete</span>
                    <span className="d-none d-md-inline-block d-lg-none"> Del</span>
                </Button>
            </Col>
            <Col xs={4} className="fw-normal p-0 text-end">
                <Button variant="outline-info"
                        disabled={props.selectedLogbookId<=0 || props.hasItems===0}
                        onClick={props.selectedLogbookId>0?props.handleCopy : null}
                >
                    <Copy /><span className=""> Copy</span>
                </Button>
            </Col>
        </Fragment>
    );
}

export default LogbookMenu;