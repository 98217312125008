import React, {Fragment} from 'react';
import {Badge} from "react-bootstrap";
import UiBadgeIcon from "./UiBadgeIcon";

function UiTableHeader({icon, text, className, badgeText, badgeClassName, reorder=false}) {
    if (!className) {
        className = "m-1 p-1";
        if (badgeText && badgeText.toString().length>1) {
            className = className + " me-" + badgeText.toString().length;
        }
    }
    if (!badgeClassName) {
        badgeClassName = "bg-secondary ";
    }

    return (
        <th className="text-center">
            {reorder && <Fragment>{text}<br /></Fragment>}
        <span className={`text-primary-emphasis d-inline ${className}`}>
            {icon}
            {badgeText &&
                <Badge pill={false}
                       text="light"
                       data-bs-theme="dark"
                       bg="primary"
                       className={`position-absolute mt-2 translate-middle ${badgeClassName}`}
                >{badgeText}</Badge>
            }
        </span>
            {!reorder && <Fragment><br />{text}</Fragment>}
        </th>
    );
}

export default UiTableHeader;