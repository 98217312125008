import React, {Fragment } from 'react';
import { Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedLogbook, logbookActions} from "../../store/logbookSlice";
import {CheckLg, X} from "react-bootstrap-icons";
import RestClient from "../../restapi/RestClient";
import AppUrl from "../../restapi/AppUrl";
import UiModal from "../UI/UiModal";

function LogbookDeleteModal({selectedLogbookId, showDelete, setShowDelete}) {
    const dispatch = useDispatch();
    const authState = useSelector(state=>state.auth);
    const selectedLogbook = useSelector(getSelectedLogbook);

    if (!selectedLogbook) {
        return (<Fragment></Fragment>);
    }

    const handleDeleteSubmit = (e) => {
        e.preventDefault();
        if (selectedLogbookId) {
            dispatch(logbookActions.setLogbooks([]));
            RestClient.PostRequest(AppUrl.RemoveLogbook, {id: selectedLogbookId}, {bearer: authState.token})
                .then(resData => {
                    setShowDelete(false);
                    dispatch(logbookActions.setSelectedLogbookId(0));
                    dispatch(logbookActions.increaseLogbooksRender());
                });
        }
    }

    const contentFormView = <Fragment>Confirm deletion of {selectedLogbook.name}?</Fragment>;


    return (
        <Fragment>
            <UiModal
                show={showDelete}
                setShow={setShowDelete}
                title="Delete confirmation"
                content={contentFormView}
            >
                <Button
                    variant="outline-danger"
                    onClick={handleDeleteSubmit}
                >
                    <CheckLg /> Delete
                </Button>
                <Button
                    variant="outline-secondary"
                    onClick={(e)=>setShowDelete(false)}
                >
                    <X /> Cancel
                </Button>
            </UiModal>
        </Fragment>
    );
}

export default LogbookDeleteModal;