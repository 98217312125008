import React, {Component, Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Footer from "../components/Footer/Footer";
import Poll from "../components/Poll/Poll";

class PollPage extends Component {
    render() {
        return (
            <Fragment>
                <div className="backgroundSettings">
                    <TopNavigation />
                    <div className="servicesOverlay">
                        <Poll />
                    </div>
                    <Footer />
                </div>
            </Fragment>
        );
    }
}

export default PollPage;